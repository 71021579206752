$titleColor: #446B8F;
$borderColor :#DCE0EB;

.box_typeid {
    flex-grow: 1;
    margin-bottom: 10px;
    flex-direction: row;
    display: flex;
}

.text_input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__content {
        padding: 1px;
        border-radius: 5px;
        background-color: $borderColor;
    }

    &__title {
        font-size: 8pt;
        font-weight: bold;
        color: #999999;
        text-align: left;
        display: block;
    }
}

.box_levels {
    display: flex;
    flex-direction: row;
    border-bottom: 1px $borderColor solid;
    margin: 10px;
}

.content_block {
    display: flex;
    flex-direction: column;
    padding: 0 6px;

    &__title {
        display: flex;
        justify-content: start;
        font-size: 12pt;
        font-weight: 600;
        color: $titleColor;
    }

    &__fields {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        align-items: center;
    }
}

.info_fields {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.typeids_boxes {
    display: flex;
    flex-direction: column;
    padding: 10px;
    row-gap: 10px;
}

.bordered_box {
    border: 1px $borderColor solid;
    border-radius: 10px;
    padding: 10px;
}

.info_fields {
    padding: 10px;
}