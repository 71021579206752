
$titleColor: #446B8F;
$borderColor :#DCE0EB;
$selectedColor: #41DDA9;

.composition__container{
    display: flex;
    flex-direction: column;
}
.composition__header{
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;
    padding-right: 10px;
}
.composition_input{
    display: flex;
    flex-direction: column;
    &__title{
        font-size: 8pt;
        font-weight: bold;
        color: #999999;
        text-align: left;
        display: block;
    }
    &__field{
        background-color: $borderColor;
        border-radius: 5px;
        padding: 1px;
    }
}

.composition_input__field.orange{
    background-color: $borderColor;
    color:orange;
}

.composition_input__field.red{
    background-color: $borderColor;
    color:red;
}

.composition_input__field.green{
    background-color: $borderColor;
    color:green;
}

.composition_input__field.orange > #level > div.dx-texteditor-container > div.dx-texteditor-input-container > input{
    color:orange;
}

.composition_input__field.red > #level > div.dx-texteditor-container > div.dx-texteditor-input-container > input{
    color:red;
}

.composition_input__field.green > #level > div.dx-texteditor-container > div.dx-texteditor-input-container > input{
    color:green;
}

.composition__content__title.orange{
    color:orange;
}

.composition__content__title.red{
    color:red;
}

.composition__content__title.green{
    color:green;
}

.composition__content__title.hidden{
    display:none
}

.composition__content{
    height: 400px;
    display: flex;
    flex-direction: row;
    border-top: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;

    &__title{
        display: flex;
        justify-content: left;
        font-size: 10pt;
        font-weight: 600;
        color: $titleColor;
        padding-bottom: 10px;
    }
    &__icon-selection{
        display: flex;
        justify-content: space-between;
        font-size: 10pt;
        font-weight: 600;
        color: $titleColor;
        padding-bottom: 10px;
        width:100%;
    }
    &__level{
        padding: 10px;
        width: 33.333%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.pallino-selezione{
    float:right;
    background-color: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #ddd;
    width:18px;
    margin-right:10px;
    cursor: pointer;
}

.pallino-selezionato{
    background-color: #41DDA9; 
}

.composition__content_border_right{
    border-right: 1px solid $borderColor;
}



.typeid_list{
    overflow-y: auto;
    &_item{
        padding: 5px;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
        column-gap: 10px;
        &__superselected{
            background-color: yellow !important;
        }
        &__selected{   
            background-color: #E8FDF6;
            border: 1px solid $borderColor;
            border-radius: 5px;
        }
    }
    &_text{
        font-size: 8pt;
        font-weight: bold;
        color: #333333;
        text-align: left;
        &__selected{
            color: $selectedColor;
        }
    }
    
    &_item:hover {
        background-color: #E8FDF6;
        cursor: pointer;
        border-radius: 5px;
    }
}

.type_id_containeré {
    background-color: aqua;
}
.type_id_container__footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 10px;
    cursor: pointer;
    padding: 10px;
}
.flex-end{
    justify-content: flex-end;
}
.flex-spacebetween{
    justify-content: space-between;
}