
.type_id_containeré {
    background-color: aqua;
}
.type_id_container__footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 10px;
    cursor: pointer;
    padding: 10px;
}
.flex-end{
    justify-content: flex-end;
}
.flex-spacebetween{
    justify-content: space-between;
}


.type_id_containeré {
    background-color: aqua;
}
.type_id_container__footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 10px;
    cursor: pointer;
    padding: 10px;
}
.flex-end{
    justify-content: flex-end;
}
.flex-spacebetween{
    justify-content: space-between;
}

.type_id_container__content.hidden {
    display: none;
}