.modal-dialog {
    width: 600px;
    height: 600px;
}

.modal-body {
    text-align: left;
    font-size: 12px;
}

.closeSpan{
    cursor:pointer;
}