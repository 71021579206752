$labelColor :#676768;
$borderColor :#efefef;
$titleColor: #2E2F32;
$selectedColor: #8DE0C6;
$chipColor: #87C3EA;

.multiselection {
    background-color: white;
    height: 220px;
    border-color: $borderColor;
    border-width: 0px;
    border-style: solid;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    border-radius: 4px;
    overflow-y: scroll;



    &--title {
        font-weight: 800;
        text-align: left;
        color: $titleColor !important;
        padding-bottom: 0px;
        font-size: 12px !important;
        align-items: center;
        height: 25px;
    }

    &--list {
        width: 20%;
    }

    &--chips {
        width: 40%;

        &-content {
            flex-grow: 1;
            overflow-y: scroll;
        }

        &--bottom {
            display: flex;
            column-gap: 4px;
            align-items: center;
        }

        &--text {
            color: $labelColor;
            font-size: 10px;
            font-weight: 600;
            display: flex;
            align-items: center;
        }
    }

    &--selections {
        width: 40%;

        &-content {
            height: auto;
            overflow-y: scroll;
            flex: 1;

        }

        &-box {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 10px;
            display: flex;
            padding: 10px 0px;
        }
    }
}

.list {
    display: flex;
    flex-direction: column;
    row-gap: 0px;

    &--item {
        text-align: start;
        color: $labelColor;
        font-size: 12px;
        font-weight: 600;
        // text-transform: lowercase;
        padding: 2px 0;

        &__selected {
            color: $selectedColor;
        }
    }

    &--item:hover {
        background: white;
        cursor: pointer;
    }

    overflow-y: scroll;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.collection {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 10px;
    overflow-y: scroll;

    &--item {
        border-color: $borderColor;
        border-width: 1px;
        border-style: solid;
        border-radius: 12px;
        padding: 0px 30px 0px 5px;
        width: fit-content;
        display: flex;
        font-size: 12px;
        font-weight: 600;
        color: $labelColor;
        height: 24px;
        align-items: center;
        justify-content: flex-start;

        &__categorized {
            background-color: $chipColor;
            color: #3D719F;
            padding: 2px 4px 2px 6px;
            height: auto;
        }

        &__selected {
            opacity: 0.2;
        }

        &__unselected {
            background-color: white;
            padding: 2px 4px 2px 6px;
            height: auto;
        }
    }

    &--item:not(.collection--item__selected):hover {
        background: #efefef;
        cursor: pointer;
    }
}

.collection--item__unselected::after {
    content: "\002B";
    font-size: 13px;
    background-color: white;
    margin-left: 10px;
    height: 18px;
    width: 18px;
    text-align: center;
    color: $labelColor;
    border-radius: 9px;
    text-align: center;
}

.collection--item__categorized::after {
    content: "\2715";
    font-size: 10px;
    background-color: white;
    margin-left: 10px;
    padding: 2px 0px 0px 0px;
    height: 18px;
    width: 18px;
    color: red;
    border-radius: 9px;
    text-align: center;
}

.box {
    border-color: $borderColor;
    padding: 10px;
    border-width: 1px;
    border-style: solid;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
