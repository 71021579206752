$labelColor :#676768;
$borderColor :#DCE0EB;
$titleColor: #446B8F;
$selectedColor: #8DE0C6;
$chipColor: #87C3EA;

.admin_rebates_edit {
    .tiers__container {
        display: flex;
        flex-direction: column;
    }

    .cellbox {
        div.row {
            padding: 8px 8px !important;
        }
    }

    .col-tier-value {
        text-align: left;
        padding: 0 4px;

        label {
            font-size: 10px;
            font-weight: bold;
            color: #999999;
            text-align: left;
            display: block;
        }

        span {
            font-size: 12px;
            font-weight: bold;
            text-align: left;
            display: block;
        }
    }

    .box__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0px 12px 4px;
        width: 100%;

        .title {
            font-size: 14px;
            font-weight: bold;
            color: #004992;
        }
    }

    .box__body {
        display: flex;
        flex-direction: row;
        border: solid 1px #f00;
        border-radius: 4px;
        padding: 8px 8px;
    }

    .boxed {
        border-color: $borderColor;
        border-width: 1px;
        border-style: solid;
        padding-bottom: 10px;
        border-bottom: none;
    }

    .empty_state {
        display: flex;
        width: auto;
        margin: 100px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__text {
            width: auto;
            display: flex;
            align-items: center;
            column-gap: 5px;
            justify-content: center;
        }

        &__title {
            font-weight: 800;
            font-size: 12pt;
            margin: 5px 0px;
            color: #333333;
        }

        &__message {
            font-weight: 800;
            color: #B2B2B2;
            font-size: 10pt;
            margin: 5px 0px;

            &_plus {
                color: green;
            }
        }

    }

    .empty_state_image {
        height: 200px;
        width: 200px;
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center;
    }

    .empty_state_tier {
        @extend .empty_state_image;
        background-image: url(../../../assets/images/Tier.svg);
    }

    .empty_state_criteria {
        @extend .empty_state_image;
        background-image: url(../../../assets/images/Criteria.svg);
    }

    .box-container {
        display: flex;
        flex-direction: column;
        justify-content: start;
        padding: 10px;
    }

    .boxed.noleft {
        border-left: none;
    }

    .boxed.noright {
        border-right: none;
    }
}