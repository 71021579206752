$labelColor :#676768;
$borderColor :#efefef;
$titleColor: #2E2F32;
$selectedColor: #8DE0C6;
$chipColor: #87C3EA;

.cellboxcontent {
    &--header {
        // height: 30px;
        align-items: center;
        display: flex;
        width: 100%;
        border-bottom: $borderColor 1px solid;

        &-item {
            background-color: white;
        }

        &-itemtitle {
            font-weight: 800;
            color: #999999;
            font-size: 11px !important;
            text-align: left;
            padding: 4px 8px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &--valuecontent__title {
        font-weight: 800;
        color: $labelColor;
        font-size: 11px !important;
        font-weight: bold;
        text-align: left;
        padding: 6px 8px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &--row {
        display: flex;
        // height: 30px;
        align-items: center;
    }

    &--row:nth-child(even) {
        background-color: #F4F5F9;
    }

    &--row:nth-child(odd) {
        background-color: #ffffff;
    }
}