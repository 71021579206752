$labelColor :#676768;
$borderColor :#efefef;
$titleColor: #2E2F32;
$selectedColor: #8DE0C6;
$chipColor: #87C3EA;

.cellbox {
    width: 100%;
    border-color: $borderColor;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 16px;
    &--header {
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        border-bottom: $borderColor 1px solid;
        align-items: center;
    }

    &--headertitle {
        font-size: 12px;
        font-weight: bold;
    }

    &--headerbuttons {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }

    &--button {
        padding: 0px 5px 2px 5px;
        border-color: $borderColor;
        border-width: 1px;
        border-style: solid;
        border-radius: 5px;
        cursor: pointer;
    }

    &--expand {
        width: 16px;
        height: 16px;
        top: 18px;
        right: 24px;
        transition-duration: 0.8s;
        transition-property: transform;
        cursor: pointer;
    }
}

.cellbox > .cellbox--content > .cellbox {
    margin: 8px;
    margin-top: 0px !important;
    width: calc(100% - 16px);
}

.buttonaccordion {
    background-color: #446B8F;
    cursor: pointer;
}