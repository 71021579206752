$titleColor :#2E2F32;
$borderColor :#DCE0EB;


.side_chart_learn_more{
    max-height: 120px;
    background-color: transparent;
    overflow: hidden;

}
.side_chart {
    height: 120px;
    background-color: transparent;
    overflow: hidden;
}

.side-bar--title {
    width: fit-content;
    padding: 2px 15px;
    border-style: solid;
    border-width: 1px;
    border-radius: 20px;
    border-color: $borderColor;
    background-color: white;
    font-size: 12px;
    font-weight: 600;
    color: $titleColor;
}

hr.solid {
    margin: 0px;
    padding: 0px;
    border-top: 1px solid #DCE0EB;
    opacity:1;
}

hr.solid.marg {
    margin-right:20px;
    margin-left:20px;
    opacity:1;
}

.dark_blue {
    color: #091f5a;
}

.light_blue {
    color: #bdcbfc;
}

.dark {
    color: #333333;
}

.light {
    color: #B2B9CC;
}

.red {
    color: #EA0000;
}

.performance {
    background-color: white;
    border-color: $borderColor;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    width: 100%;
    overflow: auto;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: white;
        padding-right: 30px;
    }

    &__selection-container {
        display: flex;
        gap: 10px;
    }

    &_graph__tooltip {
        width: 130px;
        padding: 0px;
        display: flex;
        flex-direction: column;
        gap: 7px;

        &--title {
            font-weight: 600;
            color: $titleColor;
        }

        &--row {
            display: flex;
            justify-content: space-between;
        }

        &--key {
            font-weight: 600;
        }

        &--value {
            font-weight: 600;
        }
    }

    &__title {
        display: flex;
        height: 60px;
        align-items: center;
        width: 100%;
        padding-left: 20px;
    }

    &__bottom_content {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &--button {
            border-radius: 6px;
            border-width: 0px;
            padding: 4px 10px 3px;
            background-color: #d1e7d7;
            color: #56a36a;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: #56a36a;
        }
    }

    &__content {
        display: flex;
        border-top: 1px solid $borderColor;

        &--left {
            border-right: 1px solid $borderColor;
            width: 40%;
            display: flex;
            flex-direction: column;

            &-bar {
                display: flex;
                gap: 20px;
            }

            &-top {
                height: calc(50% - 32px);
                overflow: hidden;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }

            &-bottom {
                height: calc(50% - 32px);
                overflow: hidden;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }

        &--right {
            flex-grow: 4;
            background-color: white;
            display: flex;
            gap: 10px;
            flex-direction: column;
        }

        &--filters {
            display: flex;
            gap: 10px;
        }
    }
}

.box--title {
    font-weight: 600;
    color: $titleColor;
    font-size: 16px;
}

.insets10 {
    padding: 10px;
}

.insets30 {
    padding: 20px;
}

.title_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.filter {
    border: 1px solid $borderColor;
    background-color: white;
    display: flex;
    padding: 2px;
    border-radius: 6px;
    align-items: center;
    height: 30px;

    &--button {
        cursor: pointer;
        padding: 0px 10px;
        height: 100%;
        background-color: white;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        &--text {
            font-weight: 500;
            color: #7888A5;
            font-size:10px;

            &--selected {
                color: #004992;
                font-weight: 600;
            }
        }

        &--selected {
            background-color: #DFEDFF;
        }
    }

}

.performance_legend {
    display: flex;
    gap: 10px;

    &--item {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        &-square {
            background-color: #091f5a;
            width: 15px;
            height: 15px;
            border-radius:2px;
        }

        &-text {
            display: block;
            /* or inline-block */
            text-overflow: ellipsis;
            word-wrap: break-word;
            overflow: hidden;

            font-size: 8pt;
            font-weight: 400;
            color: $titleColor;
        }
    }
}

.brand-selection {
    width: 380px;
}

.customers-selection {
    width: 250px;
}

.learn_more {
    &--header {
        padding: 10px 5px 10px 0px;
        display: flex;
        justify-content: space-between;
    }

    &--legend {
        display: flex;
        align-items: center;
    }

    &--content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding: 10px;
    }

    &--content-left {
        width: 220px;
        display: flex;
        justify-content: left;
        flex-direction: row;
        align-items: center;
    }

    &--row {
        height: 90px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 10px 0px;
        border-radius: 10px;
        border-width: 1px;
        border-color: #DCE0EB;
        border-style: solid;
    }
}

.performance__content--left-bar {
    padding: 20px;
}