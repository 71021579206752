// FONTS
// @import url(./fonts/avenir.css);
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// @import url(./fonts/proxima-nova.css);

// VARS
$default-font: 'Poppins', sans-serif;
$large-screen: 1620px;
// $default-font: 'Proxima-Nova-Regular', Arial, sans-serif;

$retrocompatibility-min-width: 1250px;

$level-0-zindex: 0;
$level-1-zindex: 999;
$level-2-zindex: 9999;
$level-3-zindex: 99999;
$max-zindex: 99999999;

$luxottica-blue-2: #091f5a;
$luxottica-blue-4: #bdcbfc;

$bar-dark: #031434;
$bar-light: #7888a5;

$header-background-color: #ffffff;
$header-foreground-color: rgb(3, 20, 52);
$footer-background-color: rgb(3, 20, 52);
$footer-title-color: #ffffff;
$footer-text-color: rgb(120, 136, 165);
$cookie-banner-background-color: rgb(3, 20, 52);
$cookie-banner-foreground-color: #fff;

$not-valid-color: #ff0000;

$font-secondary-color: #06152b;
$font-tertiary-color: #8792B1;

$label-primary-color: #BBBBBB;

$border-primary-color: #DCE0EB;
$border-secondary-color: #ECEFF6;
$border-tertiary-color: #F8FAFF;

$text-color-green: #ff0000;
$text-color-red: #ff0000;
$background-color-green: #ff0;
$background-color-red: #ff0;

$tooltip-background-color: #2E2F32;
$tooltip-foreground-color: #FFFFFF;

$add-background-color: #CFF9EB;
$add-foreground-color: #41DDA9;
$action-background-color: #0074FF;
$action-foreground-color: #FFFFFF;
$save-background-color: #1AD598;
$save-foreground-color: #FFFFFF;
$checkbox-selected-background-color: #41DDA9;
$checkbox-selected-foreground-color: #FFFFFF;

$box-background: #f7f8f9;

$font-primary-color: #031434;
$font-medium: 500;
$font-semibold: 600;

$header-height: 80px;

:root {
    --color-primary: #004992;
    --color-primary-light: #EEF6FF;
    --color-text-primary: #2E2F32;
    --color-text-secondary: #8792B1;
    --color-border-gray: #DCE0EB;
    --color-border-light-gray: #ECEFF6;
}

@keyframes fadein {
    0% {
        opacity: 0,
    }

    100% {
        opacity: 1,
    }
}

@keyframes fadeout {
    0% {
        opacity: 1,
    }

    100% {
        opacity: 0,
    }
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: $default-font;
    height: 100vh;
    margin: 0 auto;
}

/*
header {
    background-color: $header-background-color;
    padding: 16px 0;
    background-color: #fff;
    background-image: url(images/navbarbg.png);
    // background-attachment: fixed;
    background-position: right top;
    background-repeat: no-repeat;
    // background-size: contain;
    box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.1);
    border-bottom: solid 1px #eee;
    min-width: $retrocompatibility-min-width;
    margin-bottom: 36px;

    .logo {
        height: 54px;
        justify-content: start;
        align-items: center;
        padding: 0 16px !important;
        display: flex;
        flex-direction: row;

        .essilor {
            width: auto;
            height: 20px;
        }

        .separator {
            width: 1px;
            margin: 0 16px;
            height: 100%;
            border-left: solid 1px #eee;
        }

        .rh {
            width: auto;
            height: 54px;
        }
    }
}
*/

#main-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $header-height;
    background-color: #fff;
    z-index: 999;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    min-width: 830px;
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.05);
    
    div {
        width: auto;
    }

    .logo {
        padding-left: 20px;
        display: flex;
        flex-direction: row;
        justify-content: start;
        min-width: 300px;
        align-items: center;

        a {
            display: block;

            img {
                width: 90px;
                height: auto;
            }
        }

        .circle-button {
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            border: solid 1px #dbdce0;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;

            img {
                color: #031434;

                &.menu {
                    width: 16.5px;
                    height: 16.4px;
                    padding: 0 0px 0 0;
                }
            }
        }
    }

    .search {
        text-align: center;
    }

    nav {
        width: auto;
        display: inline-block;
        
        ul {
            display: flex;
            list-style-type: none;
            background-color: #f7f8f9;
            padding: 4px;
            border-radius: 8px;
            border: solid 1px #dbdce0;
            margin: 0 auto;

            li {
                display: inline;
                margin: 0 0;
                padding: 10px 6px;
                border-radius: 6px;
                cursor: pointer;

                a, a:link, a:hover, a:visited {
                    // font-family: GilmerMedium;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.13;
                    
                    text-align: center;
                    color: #7888a5;
                    text-decoration: none;
                }

                &.selected,
                &:hover {
                    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                    background-color: #fff;

                    a {
                        color: #031434;
                    }
                }
            }
        }
    }

    .search {
        flex-grow: 1;

        .search-box form {
            width: 400px;
        }
    }

    .actions {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
        min-width: 300px;

        a {
            margin-left: 20px;
        }

        .circle-button {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            border: solid 1px #dbdce0;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            img {
                color: #031434;

                &.search {
                    width: 16.5px;
                    height: 16.4px;
                    padding: 0 0px 0 0;
                }

                &.export {
                    width: 16.5px;
                    height: 20.3px;
                    margin: 0 1.8px 0 1.8px;
                    padding: 0.2px 0.2px 0.2px 0.1px;
                }
            }
        }

        .user-info {
            display: flex;
            flex-direction: row;
            justify-content: end;
            align-items: center;
            padding: 3px 10px 3px 3px;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            border: solid 1px #dbdce0;
            border-right: none;
            background-color: #fff;

            .circle-button {
                width: 34px;
                height: 34px;
                background-color: #031434;
            }

            img {
                color: #031434;
            }

            .details {
                width: auto;
                padding-left: 8px;

                .name {
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: $font-medium;

                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    
                    text-align: right;
                    color: #7888a5;
                }

                .role {
                    font-family: Poppins;
                    font-size: 10px;
                    font-weight: $font-medium;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.6;
                    
                    text-align: right;
                    color: #7888a5;
                }
            }
        }

        .bubble {
            position: absolute;
            top: 70px;
            margin-right: 10px;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.3), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
            display: flex;
            width: 226px;
            justify-content: center;
            z-index: 6;
            animation: ease 1s;
            animation-name: fadein;
            flex-direction: column;
            padding: 16px;
    
            button {
                display: flex;
                align-items: center;
                font-weight: $font-medium;
                justify-content: left;
                cursor: pointer;
                border: none;
                font-size: 14px;
                border-radius: 8px;
                height: auto;
                // margin: 16px;
                width: 194px;
    
    
                &.logout {
                    color: #EA3A3D;
                    background-color: #fbdde2;
    
                    &:hover {
                        color: white;
                        background-color: #EA3A3D;
    
                        div {
                            background-image: url(icons/outline/ExitWhite.svg);
                        }
                    }
    
                    .icon {
                        width: 16px;
                        height: 16px;
                        border-image: none;
                        background-image: url(icons/outline/Logout.svg);
                        background-repeat: no-repeat;
                        margin: 10px;
                        display: flex;
                        align-items: center;
                    }
                }
    
                &.rebate {
                    color: #004992;
                    background-color: #ffffff;
                    border: solid 1px #004992;
    
                    &:hover {
                        color: #ffffff;
                        background-color: #004992;
                        border: solid 1px #004992;
    
                        div {
                            background-image: url(icons/outline/RebateToolWhite.svg);
                        }
                    }
    
                    .icon {
                        width: 16px;
                        height: 16px;
                        border-image: none;
                        background-image: url(icons/outline/RebateTool.svg);
                        background-repeat: no-repeat;
                        margin: 10px;
                        display: flex;
                        align-items: center;
                    }
                }
    
                &.type_id {
                    color: #004992;
                    background-color: #ffffff;
                    border: solid 1px #004992;
    
                    &:hover {
                        color: #ffffff;
                        background-color: #004992;
                        border: solid 1px #004992;
    
                        div {
                            background-image: url(icons/outline/RebateToolWhite.svg);
                        }
                    }
    
                    .icon {
                        width: 16px;
                        height: 16px;
                        border-image: none;
                        background-image: url(icons/outline/RebateTool.svg);
                        background-repeat: no-repeat;
                        margin: 10px;
                        display: flex;
                        align-items: center;
                    }
                }
    
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
    
    
                &:before {
                    content: "''";
                    position: absolute;
                    bottom: 100%;
                    right: 27px;
                    width: 0;
                    border-bottom: 6px solid white;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                }
            }
        }
    }   
}

#left-menu {
    width: 218px;
    background-color: #fff;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -250px;
    z-index: 9999999;
    display: flex;
    flex-direction: column;
    border-right: solid 1px #dbdce0;
    transition: all .2s ease;

    &.opened {
        left: 0;
    }

    &.closed {
        left: -250px;
    }

    .head {
        padding-left: 20px;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        padding: 10px 0 10px 20px;
        box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.05);
        background-color: #fff;

        img {
            width: 90px;
            height: 60px;
        }
    }

    .circle-button {
        cursor: pointer;
        position: absolute;
        right: -20px;
        top: 20px;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        border: solid 1px #dbdce0;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            color: #031434;

            &.close {
                width: 16.5px;
                height: 16.4px;
                padding: 0 0px 0 0;
            }
        }
    }

    nav {
        width: auto;
        display: inline-block;
        background-color: #f7f8f9;
        height: 100%;
        overflow: auto;

        ul {
            position: relative;
            height: 100%;
            list-style-type: none;
            padding: 4px;
            margin: 0 auto;

            li {
                margin: 8px 4px;
                padding: 10px;
                border-radius: 6px;
                cursor: pointer;

                a, a:link, a:hover, a:visited {
                    // font-family: GilmerMedium;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.13;
                    
                    text-align: center;
                    color: #7888a5;
                    text-decoration: none;
                    display: flex;
                    margin: 2px 0px;
                }

                &.selected,
                &:hover {
                    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                    background-color: #fff;

                    a {
                        color: #031434;
                    }
                }
            }
        }
    }
}

/*
text {
    font-family: $default-font !important;
    font-weight: bold !important;
    font-size: 11px !important;
}
*/

.hidden {
    display: none;
}

.user {
    display: flex;
    align-items: center;
    justify-content: end;

    .account {
        height: 36px;
        width: auto;
        z-index: 40;
        // marginLeft: 100px;
        // min-width: 140px;
        border: none;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
        box-shadow: 0 4px 15px -8px #06060657;
        background-color: #fff;
        display: flex;
        cursor: pointer;

        .icon {
            margin: 3px 0 3px 3px;
            width: 30px;
            height: 30px;
            border: none;
            border-radius: 50px;
            background-color: $header-foreground-color;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .details {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: start;
            padding: 0 10px;

            .name {
                font-size: 14px;
                font-weight: bold;
                color: #8792B1;
                margin: 0;
            }

            .role {
                font-size: 11px;
                font-weight: $font-medium;

                color: #8792B1;
                margin: 0;
                margin-top: -4px;
            }
        }
    }

    .bubble {
        position: absolute;
        top: 70px;
        margin-right: 10px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.3), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
        display: flex;
        width: 226px;
        justify-content: center;
        z-index: 6;
        animation: ease 1s;
        animation-name: fadein;
        flex-direction: column;
        padding: 16px;

        button {
            display: flex;
            align-items: center;
            font-weight: $font-medium;
            justify-content: left;
            cursor: pointer;
            border: none;
            font-size: 14px;
            border-radius: 8px;
            height: auto;
            // margin: 16px;
            width: 194px;


            &.logout {
                color: #EA3A3D;
                background-color: #fbdde2;

                &:hover {
                    color: white;
                    background-color: #EA3A3D;

                    div {
                        background-image: url(icons/outline/ExitWhite.svg);
                    }
                }

                .icon {
                    width: 16px;
                    height: 16px;
                    border-image: none;
                    background-image: url(icons/outline/Logout.svg);
                    background-repeat: no-repeat;
                    margin: 10px;
                    display: flex;
                    align-items: center;
                }
            }

            &.rebate {
                color: #004992;
                background-color: #ffffff;
                border: solid 1px #004992;

                &:hover {
                    color: #ffffff;
                    background-color: #004992;
                    border: solid 1px #004992;

                    div {
                        background-image: url(icons/outline/RebateToolWhite.svg);
                    }
                }

                .icon {
                    width: 16px;
                    height: 16px;
                    border-image: none;
                    background-image: url(icons/outline/RebateTool.svg);
                    background-repeat: no-repeat;
                    margin: 10px;
                    display: flex;
                    align-items: center;
                }
            }

            &.type_id {
                color: #004992;
                background-color: #ffffff;
                border: solid 1px #004992;

                &:hover {
                    color: #ffffff;
                    background-color: #004992;
                    border: solid 1px #004992;

                    div {
                        background-image: url(icons/outline/RebateToolWhite.svg);
                    }
                }

                .icon {
                    width: 16px;
                    height: 16px;
                    border-image: none;
                    background-image: url(icons/outline/RebateTool.svg);
                    background-repeat: no-repeat;
                    margin: 10px;
                    display: flex;
                    align-items: center;
                }
            }

            &:not(:last-child) {
                margin-bottom: 16px;
            }


            &:before {
                content: "''";
                position: absolute;
                bottom: 100%;
                right: 27px;
                width: 0;
                border-bottom: 6px solid white;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
            }
        }
    }
}

footer {
    padding-top: 16px;
    background-color: $footer-background-color;
    // min-width: $retrocompatibility-min-width;

    ul {
        font-size: 12px;
        list-style: none;
        list-style-position: inside;
        padding: 0;

        li {
            margin: 0;
            padding: 2px 0;
            color: $footer-text-color;
            font-weight: $font-medium;

        }
    }

    a,
    a:link,
    a:visited,
    a:hover {
        text-decoration: none;
        color: $footer-text-color;
    }

    h3 {
        color: $footer-title-color;
        font-size: 21px;
        padding: 8px 0;

        span {
            font-weight: bold;
        }
    }

    h4 {
        color: $footer-title-color;
        font-size: 21px;
        font-weight: bold;
        padding: 8px 0;
    }

    .copyright {
        border-top: 1px solid rgb(30, 42, 63);
        font-size: 10px;
        color: $footer-text-color;
        text-align: center;
        padding: 16px 0;
        font-weight: $font-medium;

    }
}

.swiper-button-next:after {
    font-family: none;
    font-size: 40px;
    margin-top: 13px;
    text-transform: none !important;
    // letter-spacing: 0;
    content: url(../assets/icons/solid/SlideNext.svg);
}

.swiper-button-prev:after {
    font-family: none;
    font-size: 40px;
    margin-top: 13px;
    text-transform: none !important;
    // letter-spacing: 0;
    content: url(../assets/icons/solid/SlidePrev.svg);
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
    left: 15px;
    right: auto;
}

.swiper-button-next {
    right: 15px;
    left: auto;
}

.swiper-slide img {
    width: 20px;
    height: 20px;
}

section {
    // background-color: #fff;

    &.main {
        min-height: calc(100vh - 290px);
        padding-top: calc($header-height + 30px);

        div.search {
            padding: 80px 0;
            display: flex;
            flex-direction: column;
            align-items: center;

            div.box {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 80px;
                width: 100%;
                border: none;

                .search-box {
                    width: 80%;
                }
            }
        }

        div.serp {
            display: flex;
            flex-direction: column;
            padding-bottom: 40px;
            margin-bottom: 20px;
        }

        div.dashboard {
            display: flex;
            flex-direction: column;
            align-content: center;
            align-items: center;
            margin-bottom: 36px;
            min-width: $retrocompatibility-min-width;
            // paddingLeft: '16px',
            // paddingRight: '16px',
        }

        div.unauthorized {
            display: flex;
            flex-direction: column;
        }
    }

    &.admin {
        position: relative;
        top: -64px;
    }

    /*
    display: flex;
  flex-direction: column;
  align-items: center;
  height: window.innerHeight <= 600 ? '400px' : '535px;
  zIndex: 5;
  border-bottom-left-radius: 30px;
  borderBottomRightRadius: '30px;
  marginBottom: '280px;
  width: '100vw;
  */
}

img.logo {
    width: auto;
    height: auto;
}

a,
a:link,
a:hover,
a:visited {
    text-decoration: none;
}

.tooltip {
    position: absolute;
    top: 48px;
    z-index: 9999999;
}

.tooltip-container {
    background-color: #2E2F32;
    color: white;
    padding: 16px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    font-weight: bold;
    box-shadow: 0 5px 20px rgba(0,0,0,10%);
    line-height: 16px;
    text-align: center;

  &.search {
    background-color: #DCE0EB;
    color: #2E2F32;
    padding: 10px;
    font-size: 12px;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    border: 10px solid transparent;
    border-top-color: #2E2F32;
    border-bottom: 0;
    margin-left: -10px;
    margin-bottom: -10px;
  }

  &.search:before {
    border-top-color: #DCE0EB;
  }

  &.top-pointing:before {
    width: 0;
    height: 0;
    bottom: 100%;
    border: .75rem solid transparent;
    border-top: none;
    border-bottom-color: #2E2F32;
  }

  &.top-pointing.search:before {
    width: 0;
    height: 0;
    bottom: 100%;
    border: .75rem solid transparent;
    border-top: none;
    border-bottom-color: #DCE0EB;
  }
}

.tooltip-box {
    position: absolute;
    margin-top: 10px;
    z-index: 5;

    &.serp {
        margin-top: 70px;
    }
}

.generic-tooltip {
    background-color: #2E2F32;
    color: white;
    padding: 16px;
    font-size: 12px;
    display: grid;
    justify-content: center;
    border-radius: 8px;
    font-weight: bold;
    box-shadow: 0 5px 20px rgba(0,0,0,10%);
    width: 150px;
    line-height: 11px;
    margin-left: 0;

    &.left {
        margin-left: 110px;
    }

    &.search {
        background-color: #DCE0EB;
        color: #2E2F32;
        padding: 10px;
        font-size: 12px;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        border: 10px solid transparent;
        border-top-color: #2E2F32;
        border-bottom: 0;
        margin-left: -10px;
        margin-bottom: -10px;
    }

    &.search:before {
        border-top-color: #DCE0EB;
    }

    &.top-pointing:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: 100%;
        border: .75rem solid transparent;
        border-top: none;
        border-bottom-color: #2E2F32;
    }

    &.top-pointing.search:before {
        border-bottom-color: #DCE0EB;
    }
}

.tab-button {
    height: 45px;
    width: 145px;
    background: #ECEFF6;
    border: 1px solid #DCE0EB;
    border-radius: 10px;
    margin: 0 5px;
    font-weight: $font-medium;

    font-size: 12px;
    padding: 0 15px;
    position: relative;
    color: #2E2F32;
    box-shadow: none;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.6;

    &.data {
        cursor: pointer;
        pointer-events: all;
        opacity: 1.0;
    }

    &.active {
        background: #0074FF;
        border: none;
        color: white;
        box-shadow: 3px 5px 9px #cccccc;   
    }
}

.switch {
    margin: 0 2px;
    width: 72px;
    height: 26px;
    position: absolute;
    background-color: #CCE3FF;
    border: none;
    border-radius: 7px;
    transform: translateX(0);

    &.toggle {
        transform: translateX(70px);
    }
}


.tier-chart-container {
    height: auto;
    margin: 8px 0;
    display: flex;
    padding: 12px 0px;
    flex-direction: column;
    margin-bottom: 18px;

    h3 {
        margin-left: 0px !important;
    }

    .tier-chart {
        display: flex;
        flex-direction: column;
        width: 98%;
        height: 80px;
        position: relative;

        .tier-chart-item {
            height: 26px;
            width: 100%;
            display: flex;
            margin: 6px 0;
            align-items: center;
            position: absolute;
            top: 24px;

            &.current {
                z-index: 20;

                .tier-bar-label {
                    position: absolute;
                    z-index: 20;
                    font-size: 12px;
                    font-weight: $font-medium;
                    color: $font-primary-color;
                    width: auto;
                    // border-left: solid 3px $luxottica-blue-2;
                    top: 24px;
                    left: -4px;
                    display: flex;
                    align-items: end;
                    padding: 0 4px;
                    font-size: 16px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    
                    text-align: left;
                    color: #2e2f32;

                    b {
                        color: $luxottica-blue-2;
                    }
                }

                
            }

            &.target {
                z-index: 10;

                .target-box {
                    position: absolute;
                    width: 100%;
                    left: 0;
                    right: 0;
                    height: 40px;
                    z-index: 60;
                    top: -35px;
            
                    .target {
                        position: absolute;
                        width: 120px;
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        // left: calc(20% - 100px);
                        top: 10px;
            
                        /*
                        .line {
                            background-color: #f00;
                            width: 4px;
                            height: 32px;
                            background-color: #031434;
                        }
                        */
            
                        .value {
                            // min-width: 140px;
                            width: auto;
                            height: auto;
                            display: flex;
                            background-color: #031434;
                            border-radius: 16px;
                            padding: 8px 12px;
                            font-size: 14px;
                            font-weight: 500;
                            font-stretch: normal;
                            font-style: normal;
                            
                            color: #fff;
                            align-items: center;
                            justify-content: center;
            
                            /*
                            &.leftline {
                                // margin-left: 100px;
                            }
            
                            &.rightline {
                                // margin-right: 100px;
                            }
                            */
                            
                            /*
                            &:after,
                            &:before {
                                top: 100%;
                                border: solid transparent;
                                content: " ";
                                height: 0;
                                width: 0;
                                position: absolute;
                                pointer-events: none;
                            }
                            */

                            &:after {
                                content: "";
                                position: absolute;
                                top: 100%;
                                left: 50%;
                                margin-left: -5px;
                                border-width: 5px;
                                border-style: solid;
                                border-color: #031434 transparent transparent transparent;
                            }
                        }
                    }
                }

                .tier-bar-label {
                    position: absolute;
                    z-index: 20;
                    font-size: 12px;
                    font-weight: $font-medium;
                    color: $font-primary-color;
                    width: auto;
                    border-right: solid 3px $luxottica-blue-4;
                    top: -18px;
                    display: flex;
                    align-items: start;
                    padding: 0 4px;
                    height: 18px;
                    justify-content: right;
                    min-width: 300px;

                    &.left {
                        left: 12px;
                        border-left: solid 3px $luxottica-blue-4;
                        border-right: none;
                        justify-content: left;
                    }

                    b {
                        color: $luxottica-blue-4;
                    }
                }

                .tier-bar-label-up {
                    padding: 7px 10px;
                    border-radius: 12px;
                    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
                    border: solid 1px rgba(0, 0, 0, 0);
                    background-color: #7888a5;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    
                    text-align: left;
                    position: absolute;
                    right: 0px;
                    top: -24px;

                    &.upper {
                        top: -52px;
                    }
                }

                .tier-bar-label-down {
                    position: absolute;
                    right: 0px;
                    top: 24px;
                    font-size: 16px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    
                    text-align: right;
                }
            }

            .tier-bar {
                height: 6px;
                border-radius: 3px;
                display: flex;
                align-items: center;
                z-index: 300;
                min-width: 6px;
                background-color: $bar-dark;
                position: absolute;
                z-index: 10;
                justify-content: right;

                .tier-indicator {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background-color: #031434;
                    margin-right: -6px;
                }
            }

            .tier-target-bar {
                justify-content: right;
                position: absolute;
                z-index: 10;
                background-color: $bar-light;
            }

            /*
            .tier-bar-label {
                position: absolute;
                z-index: 20;
                font-size: 12px;
                font-weight: $font-medium;
                padding: 0px 12px 0px 12px;
                background-color: $border-primary-color;
                color: $font-primary-color;
                border-radius: 24px;
                margin: 24px 0px 0px 0px;
                width: auto;
            }
            */
        }
    }
}

table.tier {
    margin-bottom: 6px;
}

.total-box {
    margin: 0 auto;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    // justify-content: start;
    margin-top: 8px;
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    justify-content: center;
    // text-align: left;
    color: #031434;
    padding: 0 20px;

    .total-title {
        font-weight: normal;
    }

    .total-value {
        font-weight: bold;
        margin-right: 8px;
    }
}

#cookie-banner {
    position: fixed;
    bottom: 0;
    z-index: $level-3-zindex;
    padding: 32px;
    background-color: $cookie-banner-background-color;
    color: $cookie-banner-foreground-color;
    font-size: 15px;

    a {
        color: $cookie-banner-foreground-color;
        text-decoration: underline;
        font-weight: $font-medium;
    }

    .buttons {
        display: flex;
        justify-content: end;
        padding-top: 24px;

        button {
            border: none;
            padding: 16px 24px;
            margin: 0 4px;
            text-transform: uppercase;
            background-color: rgb(247, 248, 249);
            color: $cookie-banner-background-color;
            border-radius: 8px;
            font-weight: $font-medium;

            font-size: 14px;

            &.accept {
                text-decoration: underline;
            }
        }
    }
}

#unauthorized {
    margin: 0;
    padding: 32px 0;

    div.image {
        height: 180px;
    }

    div.title {
        text-align: center;
        font-style: bold;
        white-space: pre-wrap;
        font-size: 38px;
    }

    div.content {
        margin-top: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 22px;
        font-style: bold;
        white-space: pre-wrap;
    }
}

#results-count {
    margin-top: 0;
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding-top: 14px;
    text-align: left;
    font-size: 14px;
}

#savings,
#rewards-rebates {
    h2 {
        width: 100%;
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        padding-right: 60px;
    }

    div.selector {
        display: flex;
        flex-direction: row;
        width: auto;
        padding: 3px;
        border-radius: 6px;
        border: solid 1px #dbdce0;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        
        text-align: center;
        color: #7888a5;
        position: absolute;
        right: 60px;

        > div {
            padding: 4px 10px 3px;
            border-radius: 4px;
            background-color: #fff;
            cursor: pointer;

            &.selected {
                color: #fff;
                background-color: #091f5a;
            }
        }
    }

    div.box {
        flex-direction: row;
        background-color: #f7f8f9;
    
        div.savings,
        div.total {
            text-decoration: none;
            border: 1px solid $border-primary-color;
            border-radius: 10px;
            padding: 8px 12px;
            background-color: #fff;
        }

        div.savings {
            width: 65%;
            min-height: 140px;

            .no-data {
                height: 100%;
            }
        }

        div.total {
            width: 34%;
            position: relative;

            .value {
                font-size: 30px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                
                text-align: left;
                color: #031434;
                // padding-top: 16px;
            }

            .label {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                
                text-align: left;
                color: #031434;
            }

            .percentage {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.17;
                
                text-align: left;
                color: #031434;
                position: absolute;
                bottom: 16px
            }
        }
    }

    .simple-table.rebate {
        border-collapse: collapse;
        border-radius: 10px;
        border-style: hidden;
        font-size: 12px;
        overflow: hidden;
        width: 100%;

        th {
            color: #8792B1;
            border: none;
            border-collapse: collapse;
            border-radius: 10px;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            
            text-align: left;
            color: #031434;
            padding-left: 10px;
            padding-top: 6px;
            padding-bottom: 6px;
            text-align: left;
        }

        td {
            border: none;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            
            text-align: center;
            color: #031434;
            padding-right: 10px;
            padding-top: 6px;
            padding-bottom: 6px;    
            text-align: right;       
        }
    }
}

#rewards-rebates {
    .box {
        flex-wrap: wrap;
    }

    .learn-more-container {
        width: 100%;
        padding: 12px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .learn-more-button {
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            
            text-align: center;
            color: #091f5a;
            border-radius: 6px;
            border: solid 1px #091f5a;
            background-color: transparent;
            padding: 4px 10px;
        }
    }

    .printable,
    .modal {
        h3 {
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            
            text-align: left;
            color: #031434;
        }

        .separator {
            height: 24px;
        }

        .rebates-breakdown {
            display: flex;
            flex-direction: row;
            min-height: 150px;

            .breakdown-table,
            .breakdown-table-nodata {
                width: 65%;
                margin-right: 1%;
            }

            .breakdown-table-nodata {
                text-decoration: none;
                border: 1px solid #DCE0EB;
                border-radius: 10px;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .total {
                width: 34%;
                position: relative;
            }
        }

        .potential-table {
            width: 100%
        }

        .potential-table,
        .breakdown-table {
            border-radius: 10px;
            border-collapse: separate !important;
            border: solid 1px #DCE0EB;
            border-spacing: 0;

            th, td {
                padding: 8px;
            }

            td {
                text-align: center;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                
                text-align: center;
                color: #031434;

                &.gray {
                    font-size: 14px;
                    color: #7888a5;
                    font-weight: 600;
                }
            }

            th {
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                
                text-align: left;
                color: #031434;
            }
        }
    }
}

#brand-data {

    &[data-brand] {
        display: none;
    }

    &[data-brand="0"] {
        display: flex !important;
    }

    h4 {
        font-weight: bold;
        font-size: 16px;
        margin: 0px;
        margin-right: 15px;
    }

    .expanded {
        padding-bottom: 8px;
    }

    .data-row {
        display: flex;
        align-items: center;
        margin: 0 16px;
    }

    .chart-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: auto;
        background-color: white;
        border-top: 1px solid $border-primary-color;
        // max-width: 700px;
    }

    .legend-box {
        display: flex;
        width: 100%;
        justify-content: start;
        padding: 20px;
        align-items: center;
        justify-content: space-between;

        h4 {
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            
            text-align: left;
            color: #031434;
        }

        .legend-items {
            display: flex;
            flex-direction: row;

            .legend-item {
                margin: 0;
                display: flex;
                align-items: center;
                margin-right: 15px;

                .legend-title {
                    font-size: 10px;
                    font-weight: $font-medium;

                    color: #8792B1;
                    margin-left: 5px;
                    margin-top: 2px;
                }

                .legend-color {
                    width: 10px;
                    height: 10px;
                    border-radius: 2px;

                    &.py {
                        background-color: $luxottica-blue-4;
                    }

                    &.cy {
                        background-color: $luxottica-blue-2;
                    }
                }
            }
        }
    }

    .actions {
        background-color: #fff !important;
        display: flex;
        width: 100%;
        padding: 16px 20px;
        align-items: center;
        justify-content: space-between;

        .brand-selection {
            border: solid 1px #dbdce0;
            border-radius: 8px;
            padding: 2px;
            width: 200px;
        }

        .separator {
            width: calc(100% - 40px);
            margin: 0 20px;
            height: 1px;
            background-color: #dbdce0;
        }
    }

    .price-box {
        height: 86px;
        display: flex;
        justify-content: center;

        .price {
            width: 480px;
            height: 30px;
            font-size: 14px;
            font-weight: bold;
            border: 1px solid $border-primary-color;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 22px 0 32px 0;
        }
    }

    .sales-chart-box {
        margin: 0 15px 0 15px;
        width: 96%;
    }

    .message {
        font-size: 10px;
        color: #000;
        padding: 10px 0 0 20px;
        font-weight: bold;
    }

    .simple-table {
        border-collapse: separate;
        border-radius: 10px;
        // border-style: hidden;
        // box-shadow: 0 0 0 1px $border-primary-color;
        border: solid 1px $border-primary-color;
        font-size: 12px;
        margin: 16px 0 0px 0;
        overflow: hidden;
        width: 100%;

        th {
            color: #8792B1;
            // border: 1px solid #DCE0EB;
            border-collapse: collapse;
            border-radius: 10px;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            
            text-align: left;
            color: #031434;
            padding-left: 10px;
            padding-top: 6px;
            padding-bottom: 6px;
            max-width: 46px;
        }

        td {
            min-width: 100px;
            // border: 1px solid #DCE0EB;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            
            text-align: center;
            color: #031434;
            padding-top: 6px;
            padding-bottom: 6px;           

            &.gray {
                color: $font-tertiary-color;
                font-weight: $font-medium;

            }

            &.label {
                max-width: 185px;
                align-items: center;
                font-weight: $font-medium;

                color: $font-tertiary-color;
            }

            &.red {
                color: #FF0000;
            }
        }

        &.rebate {
            height: 100%;

            tbody {
                align-content: start;
            }

            tr {
                height: 24px;
                display: inline-block;
                width: 100%;
                margin: 4px 0;
                padding-top: 4px;
            }

            th {
                max-width: none !important;
                display: block;
                width: 70%;
                float: left;
                border: none;
                padding-left: 12px;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                
                text-align: left;
                color: #031434;
            }

            td {
                width: 30%;
                border: none;
                text-align: right;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                
                color: #031434;
                padding-right: 18px;
            }
        }
    }

    .scaling-box {
        display: flex;
        justify-content: center;
        // height: 48px;

        .scaling {
            display: flex;
            justify-content: center;
            align-items: center;
            // height: 48px;

            .scalingIconBox {
                width: 18px;
                height: 18px;
                min-width: 18px;
                min-height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50px;
                margin-right: 8px;
            }
        }

        .info {
            font-size: 12px;
            font-weight: bold;
        }

        .value {
            margin-right: 4px;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            
            text-align: left;
        }
    }

    .details-box {
        display: flex;
        flex-direction: column;
        width: 70%;
        height: 435px;
        background-color: white;
        border: 1px solid $border-primary-color;
        border-left: none;

        h4 {
            padding-left: 30px;
            padding-top: 16px;
            padding-bottom: 16px;
        }

        .no-data {
            height: 100%;
        }
    }

    .date-label {
        margin-left: 0px;
        margin-top: 12px;
    }

    .brand-rebate-box {
        // border-radius: 10px;
        width: 100%;
        background-color: white;
        border-top: 1px solid $border-primary-color;
        display: flex;
        flex-direction: column;
        // border-bottom-right-radius: 10px;
        // border-bottom-left-radius: 10px;
        margin-bottom: 16px;

        &.semester,
        &.quarter {
            // border-bottom-right-radius: 0;
            // border-bottom-left-radius: 0;
            // margin-bottom: 0;
        }

        .title-box {
            display: flex;
            padding: 16px 0 8px 8px;
            align-items: center;

            .title {
                font-size: 16px;
                color: $font-primary-color;
                font-weight: $font-medium;

                margin-left: 12px;
                margin-top: 4px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: self-start;
            }
        }

        .brand-data-box {
            margin: 0 20px;
            margin-bottom: 60px;
            background-color: #fff;

            .brand-chart-box {
                border: 1px solid $border-primary-color;
                width: 100%;
                /* height: 146px; */
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                /* align-self: center; */
                border-radius: 10px;
                padding: 16px 0 8px 0;

                .stacked-bar-box {
                    width: 98%;
                    margin: 0 1%;
                    position: relative;

                    .stacked-bar {
                        border: solid 1px $border-primary-color;
                        width: 100%;
                        border-radius: 30px;

                        .stacked-bar-background {
                            border-radius: 30px;
                            background-color: $luxottica-blue-4;
                            height: 28px;
                            border: solid 2px #fff;
                            position: relative;

                            .stacked-bar-progress {
                                height: 24px;
                                border-radius: 30px;
                                display: flex;
                                justify-content: right;
                                align-items: center;
                                position: absolute;
                                z-index: 200;
                                min-width: 24px;

                                .stacked-bar-indicator {
                                    height: 18px;
                                    width: 18px;
                                    background-color: #fff;
                                    border-radius: 50%;
                                    margin: 0 3px;
                                }
                            }
                        }
                    }
                }

                .stacked-bar-info {
                    display: flex;
                    flex-direction: row;
                    padding: 0 16px;
                    justify-content: space-between;

                    span.value {
                        color: #000;
                        padding-left: 5px;
                    }

                    .left-value {
                        justify-content: left;
                        display: flex;
                        font-size: 12px;
                        color: rgb(135, 146, 177);
                    }

                    .right-value {
                        justify-content: right;
                        display: flex;
                        font-size: 12px;
                        color: rgb(135, 146, 177);
                    }
                }
            }
        }
    }

    .tier-chart-box {
        padding: 0 20px;
    }

    .tier-chart-container .tier-chart .tier-chart-item.target .target-box .target .value {
        padding: 2px 20px;
    }

    .tier-chart-container .tier-chart .tier-chart-item.target .tier-bar-label-up {
        padding: 2px 10px;
    }
}

// OK
#account-information,
#account-information-small,
#account-information-cover {
    text-decoration: none;

    .expandable {
        padding-top: 6px;
    }

    .tier-icon {
        margin: 0 auto;
    }

    div.info {
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        line-height: 0;
        padding: 14px 0;
        width: 100%;

        &>div.icon {
            margin: 0 20px;
            width: 48px;
            height: 48px;
            border: none;
            border-radius: 24px;
            background-color: $luxottica-blue-2;
            /*
            display: flex;
            justify-content: center;
            align-items: center;
            */
            display: block;
            padding-top: 10px;
            text-align: center;
        }

        div.customer {
            flex-direction: column;
            display: flex;
        }

        div.infos {
            flex-direction: row;
            flex-grow: 1;
            justify-content: right;
            align-items: center;
            display: flex;

            & > div {
                margin-right: 20px;
            }

            div.info-box {
                padding: 17px 20px 16px;
                border-radius: 8px;
                border: solid 1px #dbdce0;
                background-color: #fff;
                height: 74px;

                &.el360 {
                    flex-direction: column;
                    // padding: 8px 17px 12px;
                    border-radius: 8px;
                    // background-color: #FCE5E5;
                    // border: none !important;

                    .text {
                        font-size: 14px;
                        font-weight: 600;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.86;
                        
                        text-align: right;
                        color: #EF6567;
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }

                    /*
                    &.status_1 {
                        background-color: #d1e7d7;

                        text {
                            color: #56a36a;
                        }
                    }
                    */

                    .icon  {
                        width: 28px;
                        height: 28px;
                        padding: 5px;
                        background-color: #fff;
                        border-radius: 50%;
                    }
                }
            }
            
            div.info-box-separator {
                width: 1px;
                height: 100px;
                background-color: #dbdce0;
                height: 74px;
            }

            div.level {
                flex-direction: column;

                span.title {
                    width: 56px;
                    margin: 6px 0 0;
                    font-size: 12px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    
                    text-align: center;
                    color: #031434;
                }
            }

            div.doors {
                flex-direction: row;
                justify-content: center;
                align-items: center;
                display: flex;
                cursor: pointer;

                span.title {
                    width: 55px;
                    font-size: 16px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.13;
                    
                    text-align: left;
                    color: #7888a5;
                    margin-left: 11px;
                    margin-right: 20px;
                }

                span.value {
                    font-size: 22px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.18;
                    
                    text-align: right;
                    color: #2e2f32;
                }
            }
        }

        div.name {
            font-size: 18px;
            font-weight: $font-semibold;
            text-overflow: ellipsis;
            color: $font-primary-color;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            
            text-align: left;
        }

        div.address {
            display: flex;
            justify-content: start;
            align-items: center;

            .text {
                color: $font-primary-color;
                font-size: 10px;
                margin-left: 4px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.6;
                
                text-align: left;
            }
        }

        div.email {
            display: flex;
            justify-content: start;
            align-items: center;

            .text {
                display: flex;
                justify-content: start;
                align-items: center;
                margin-left: 4px;
                font-size: 10px;
                font-weight: $font-medium;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.2;
                
                text-align: left;
            }
        }

        div.customer-box {
            display: flex;

            div.customer {
                display: flex;
                margin-top: 0px;

                div.label {
                    font-size: 12px;
                    padding: 12px;
                    border-radius: 30px;
                    margin-right: 10px;
                    font-weight: bold;
                    border-width: 1px;
                    border-style: solid;
                }
            }
        }

        div.el360 {
            height: 34px;
            width: 200px;
            background-color: #FCE5E5;
            border-radius: 10px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            align-self: center;
            border: solid 1px #dbdce0 !important;
            padding: 0 !important;

            .icon {
                height: 24px;
                width: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: white;
                border-radius: 6px;
            }

            .text {
                color: #EA3A3D;
                margin-right: 2px;
                font-size: 14px;
                font-weight: bold;
            }

            /*
            &.status_1 {
                background-color: #CFF9EB;

                .text {
                    color: #1AD598;
                }
            }

            &.status_2 {
                background-color: #FEF1DE;

                .text {
                    color: orange;
                }
            }
            */
        }

        div.level {
            width: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;

            .text {
                font-weight: bold;
                font-size: 12px;
                color: $font-primary-color;
                padding-top: 8px;
            }
        }
    }

    div.counter {
        display: flex;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        border: 1px solid $border-primary-color;
        background-color: #fff;
        padding: 16px;
        width: 130px;
        height: 119px;
        margin-left: 20px;

        .box {
            text-decoration: none;
            color: inherit;
            cursor: pointer;
            height: auto;
            width: 130px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            border: none;
        }

        .label {
            color: $font-tertiary-color;
            font-weight: $font-medium;

            font-size: 12px;
            text-align: center;
        }

        .icon {
            img {
                width: 30px;
                height: 30px;
            }
        }

        .value {
            font-size: 22px;
            font-weight: bold;
        }
    }

    div.divider {
        border-bottom: 1px solid $border-primary-color;
    }

    div.savings {
        border: 1px solid $border-primary-color;
        border-radius: 10px;
        line-height: 9px;
        padding: 10px 16px;
        align-items: center;
        width: 300px;

        div.data-row {
            border: 1px solid $border-primary-color;
            background-color: white;
            border-radius: 7px;
            height: 30px;
            width: 100%;
            display: flex;
            margin: 12px 0;
            align-items: center;
            justify-content: space-between;

            .square {
                width: 10px;
                height: 10px;
                border-radius: 2px;
                margin: 8px 5px 10px 10px;
            }

            .label {
                display: flex;
                font-size: 10px;
                font-weight: bold;
                align-items: center;
            }

            .value {
                display: flex;
                font-size: 12px;
                font-weight: bold;
                margin-right: 10px;
            }
        }
    }

    div.data-container {
        display: flex;
        flex-direction: column;
        height: auto;
        width: 97%;
        justify-content: start;
        margin: 8px 16px;
        border-radius: 10px;
        border: 1px solid $border-primary-color;
        border-collapse: collapse;
        background-color: #fff;

        &.horizontal {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .right-block {
                display: flex;
                padding: 16px 0px;
            }
        }
    }

    table {
        border-collapse: collapse;
        border-style: hidden;
        background-color: $border-tertiary-color;
        border-radius: 8px;
        margin: 8px;

        tr {
            background-color: #ffffff;
        }

        td {
            width: auto;
            height: 30px;
            text-align: left;
            font-size: 14px;
            color: $font-primary-color;
            border-left: none;
            border-right: none;
            padding-left: 16px;
            font-weight: $font-medium;

        }

        th {
            color: #8792B1;
            background-color: rgb(248, 250, 255);
            border-collapse: collapse;
            border-radius: 10px;
            text-align: left;
            font-weight: $font-medium;

            font-size: 14px;
            padding-left: 10px;

            img {
                width: 16px;
                height: 16px;
                margin: 5px;
                margin-bottom: 6px;
            }

            span {
                font-size: 12px;
                font-weight: bold;
                color: $font-tertiary-color;
            }
        }

        thead>tr {
            margin: 20px;
        }
    }
}

#account-information-small {
    position: fixed;
    background-color: #fff;
    z-index: 998;
    transition: all 0.5s ease-out;
    margin-top: -200px;
    left: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    min-width: 960px;

    &.show {
        margin-top: -30px;
    }

    div.data-container {
        padding: 0;
        margin: 0;
        width: 100%;
        border: none;
    }

    div.info {
        div.name {
            font-size: 16px;
        }

        div.customer-box div.customer div.label {
            font-size: 10px;
        }

        div.infos div.info-box.el360 .text {
            font-size: 10px;
        }

        div.infos div.info-box.el360 .text {
            font-size: 10px;
        }

        div.infos div.doors span.title {
            font-size: 10px;
            width: 25px;
        }

        div.infos div.doors span.value {
            font-size: 18px;
        }

        div.infos div.level span.title {
            margin-top: 12px;
        }
    }
}

//FAC
.modal-title {
    font-size: 18px;
    color: $font-primary-color;
    font-weight: $font-medium;

    display: flex;
    align-items: center;
    padding-bottom: 4px;
}

.btn-close {
    float: right;
    font-size: 10px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: 1;
}

.dx-texteditor-input {
    color: #7888A5;
}

.dx-placeholder {
    color: #7888A5;
}

#el360 {
    text-decoration: none;

    .dx-texteditor.dx-editor-outlined {
        border: solid 1px $border-primary-color;
    }

    .expandable.expanded {
        background-color: #f7f8f9;
    }

    .label {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        
        text-align: left;
        color: #7888a5;
    }

    .value {
        display: flex;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        
        text-align: right;
        color: #2e2f32;
    }

    .square {
        width: 10px;
        height: 10px;
        border-radius: 2px;
        margin: 11px 5px 10px 10px;
    }

    .form-select {
        width: auto;
        font-size: 10px;
        font-weight: bold;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 1;
        padding-right: 1;
        color: #8792B1;
    }

    .data-row {
        display: flex;
        width: auto;
    }

    .brandImage {
        /*
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin: 10px;
        border: 1px solid #8792B1;
        */
        width: auto;
        height: 36px;
        margin: 0px 7px 0px 0px;
        display: inline-block;
    }

    .horizontal-noflex {
        display: flex;
        flex-direction: row;
    }

    .title {
        display: flex;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.91;
        
        text-align: left;
        color: #031434;
    }

    .horizontal {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 99%;

        .right-block {
            display: flex;
            padding: 16px 0px;
        }
    }

    .central {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 78px;
    }

    .counters {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 50px;
        width: 72px;
        align-items: end;
    }

    div.info {
        border-radius: 10px;
        display: flex;
        line-height: 0;

        &>div.icon {
            margin: 18px 10px 0 0;
            width: 40px;
            height: 40px;
            border: none;
            border-radius: 50px;
            background-color: #0074FF;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        div.name {
            margin: 5px 0;
            height: 18px;
            font-size: 18px;
            font-weight: bold;
            text-overflow: ellipsis;
            color: $font-primary-color;
        }

        div.email {
            display: flex;
            justify-content: start;
            align-items: center;
            font-size: 12px;
            font-weight: $font-medium;

            color: #0074FF;

            .text {
                display: flex;
                justify-content: start;
                align-items: center;
                height: 12px;
                margin-left: 5px;
                padding: 16px 0;
            }
        }

        div.customer {
            display: flex;
            margin-top: -4px;

            div.label {
                font-size: 12px;
                padding: 12px;
                border-radius: 30px;
                margin-right: 10px;
                font-weight: bold;
                border-width: 1px;
                border-style: solid;
            }
        }

        div.address {
            display: flex;
            justify-content: start;
            align-items: center;
            margin: 6px 0;
            height: 20px;

            .text {
                color: $font-primary-color;
                font-size: 10px;
                margin-left: 5px;
                font-weight: $font-medium;

            }
        }

       

        div.el360 {
            height: 34px;
            width: 85px;
            background-color: #FCE5E5;
            border-radius: 10px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            align-self: center;

            .icon {
                height: 24px;
                width: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: white;
                border-radius: 6px;
            }

            .text {
                color: #EA3A3D;
                margin-right: 2px;
                font-size: 14px;
                font-weight: bold;
            }

            &.status_1 {
                background-color: #CFF9EB;

                .text {
                    color: #1AD598;
                }
            }

            &.status_2 {
                background-color: #FEF1DE;

                .text {
                    color: orange;
                }
            }
        }

        div.level {
            width: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            margin-left: 32px;


            .text {
                font-weight: bold;
                font-size: 12px;
                color: $font-primary-color;
                padding-top: 8px;
            }
        }
    }

    div.counter {
        display: flex;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        border: 1px solid $border-primary-color;
        background-color: #fff;
        padding: 16px;
        width: 119px;
        height: 119px;
        margin-left: 20px;

        .box {
            text-decoration: none;
            color: inherit;
            cursor: pointer;
            height: 80px;
            width: 120px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
        }

        .label {
            color: $font-tertiary-color;
            font-weight: $font-medium;

            font-size: 14px;
        }

        .icon {
            img {
                width: 30px;
                height: 30px;
            }
        }

        .value {
            font-size: 22px;
            font-weight: bold;
        }
    }

    div.divider {
        border-bottom: 1px solid $border-primary-color;
    }

    div.savings {
        border: 1px solid $border-primary-color;
        border-radius: 10px;
        line-height: 9px;
        padding: 10px 16px;
        align-items: center;
        width: 300px;

        div.data-row {
            border: 1px solid $border-primary-color;
            background-color: white;
            border-radius: 7px;
            height: 30px;
            width: 100%;
            display: flex;
            margin: 12px 0;
            align-items: center;
            justify-content: space-between;

            .square {
                width: 10px;
                height: 10px;
                border-radius: 2px;
                margin: 8px 5px 10px 10px;
            }

            .label {
                display: flex;
                font-size: 10px;
                font-weight: bold;
                align-items: center;
            }

            .value {
                display: flex;
                font-size: 12px;
                font-weight: bold;
                margin-right: 10px;
            }
        }
    }

    div.data-container {
        display: flex;
        flex-direction: column;
        height: auto;
        width: 97%;
        justify-content: start;
        margin: 16px 1.5%;
        border-radius: 10px;
        border: 1px solid $border-primary-color;
        border-collapse: collapse;

        &.horizontal {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .right-block {
                display: flex;
                padding: 16px 0px;
            }
        }
    }

    table {
        border-collapse: collapse;
        border-style: hidden;
        background-color: $border-tertiary-color;
        border-radius: 8px;
        margin: 16px 0px;
        border: 1px solid $border-primary-color;

        tr {
            background-color: #ffffff;
        }

        td {
            width: auto;
            height: 30px;
            text-align: left;
            font-size: 14px;
            color: $font-primary-color;
            border-left: none;
            border-right: none;
            padding-left: 16px;
            font-weight: $font-medium;

        }

        th {
            color: #8792B1;
            background-color: rgb(248, 250, 255);
            border-collapse: collapse;
            border-radius: 10px;
            text-align: left;
            font-weight: $font-medium;

            font-size: 14px;
            padding-left: 10px;

            img {
                width: 16px;
                height: 16px;
                margin: 5px;
                margin-bottom: 6px;
            }

            span {
                font-size: 12px;
                font-weight: bold;
                color: $font-tertiary-color;
            }
        }

        thead>tr {
            margin: 20px;
        }
    }
}

#vsdata {

    .quarter, .semester, .annual {
        padding: 8px 16px;
    }
    
    .separator {
        height: 20px;
    }

    .message {
        padding: 10px 0 0 10px !important;
    }

    .data-container {
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
        justify-content: start;
        border-radius: 10px;
        border: 1px solid #DCE0EB;
        border-collapse: collapse;
        background-color: #fff;
    }

    .tier-chart-box {
        padding: 0 10px;
        // padding-top: 12px;
    }

    h3 {
        font-size: 16px;
        color: #2E2F32;
        font-weight: $font-medium;

        display: flex;
        /* align-self: start; */
        margin-left: 10px;
        padding: 4px 0 0 0;
        margin-top: 4px;
        width: calc(100% - 26px);

        &.rebate-title {
            border-top: solid 1px #DCE0EB;
            padding-top: 24px;
            margin-top: -1px;
        }
    }

    h3:not(:first-child) {
        margin-top: 16px;
    }

    .simple-table {
        align-self: center;
        overflow: hidden;
        width: 100%;
        font-size: 12px;
        border-collapse: separate;
        border-radius: 10px;
        border: solid 1.5px #dbdce0;
        height: 100%;

        tr {
            height: 24px;
            display: inline-block;
            width: 100%;
            margin: 4px 0;
            padding-top: 4px;
        }

        th {
            min-width: 200px;
            color: #8792B1;
            border: none;
            border-collapse: collapse;
            border-radius: 10px;
            text-align: left;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            
            text-align: left;
            color: #031434;
            padding-left: 25px;
            display: block;
            float: left;
        }

        td {
            width: 20%;
            min-width: 100px;
            text-align: right;
            border: none;
            padding-right: 16px;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            
            text-align: right;
            color: #031434;
        }
    }

    .active-brands {
        text-align: center;
        padding: 8px 0;

        .row.active {
            font-weight: bold;
            font-size: 14px;  
            padding: 8px 0;    
        }

        .row.chart {
            padding: 0 16px;

            .tier-chart {
                display: flex;
                flex-direction: column;
                width: 98%;
                height: 80px;
                position: relative;
                
                .tier-chart-tooltip {
                    position: absolute;
                    top: 55px;
    
                    .value {
                        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
                        border: solid 1px rgba(0, 0, 0, 0);
                        width: 140px;                    
                        background-color: #031434;
                        font-size: 14px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        
                        text-align: center;
                        color: #fff;
                        border-radius: 14px;

                        &:after,
                        &:before {
                            bottom: 100%;
                            border: solid transparent;
                            content: " ";
                            height: 0;
                            width: 0;
                            position: absolute;
                            pointer-events: none;
                        }

                        &:after {
                            border-width: 6px;
                            left: 50%;
                            margin-left: -6px;
                        }

                        &:before {
                            // border-color: rgba(113, 158, 206, 0);
                            border-bottom-color: #031434;
                            border-width: 6px;
                            left: 50%;
                            margin-left: -6px;
                        }
                    }
                }
                
                .tier-chart-item {
                    height: 26px;
                    width: 100%;
                    display: flex;
                    margin: 6px 0;
                    align-items: center;
                    position: absolute;
                    top: 24px;
        
                    &.current {
                        z-index: 20;
        
                        .tier-bar-label {
                            position: absolute;
                            z-index: 20;
                            font-size: 12px;
                            font-weight: $font-medium;
                            color: $font-primary-color;
                            width: auto;
                            // border-left: solid 3px $luxottica-blue-2;
                            top: 26px;
                            left: 12px;
                            display: flex;
                            align-items: end;
                            padding: 0 4px;
                            height: 18px;
        
                            b {
                                color: $luxottica-blue-2;
                            }
                        }
                    }
        
                    &.target {
                        z-index: 10;
        
                        .tier-bar-label {
                            position: absolute;
                            z-index: 20;
                            font-size: 12px;
                            font-weight: $font-medium;
                            color: $font-primary-color;
                            width: auto;
                            border-right: solid 3px $luxottica-blue-4;
                            top: -18px;
                            display: flex;
                            align-items: start;
                            padding: 0 4px;
                            height: 18px;
                            justify-content: right;
                            min-width: 300px;
        
                            &.left {
                                left: 12px;
                                border-left: solid 3px $luxottica-blue-4;
                                border-right: none;
                                justify-content: left;
                            }
        
                            b {
                                color: $luxottica-blue-4;
                            }
                        }
                    }
        
                    .tier-bar {
                        height: 6px;
                        border-radius: 3px;
                        display: flex;
                        align-items: center;
                        z-index: 300;
                        min-width: 6px;
                        background-color: $bar-dark;
                        position: absolute;
                        z-index: 10;
                        justify-content: right;
        
                        .tier-indicator {
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            background-color: #031434;
                            margin-right: -6px;
                        }
                    }
        
                    .tier-target-bar {
                        justify-content: right;
                        position: absolute;
                        z-index: 10;
                        background-color: $bar-light;
                    }

                    
        
                    /*
                    .tier-bar-label {
                        position: absolute;
                        z-index: 20;
                        font-size: 12px;
                        font-weight: $font-medium;
                        padding: 0px 12px 0px 12px;
                        background-color: $border-primary-color;
                        color: $font-primary-color;
                        border-radius: 24px;
                        margin: 24px 0px 0px 0px;
                        width: auto;
                    }
                    */
                }
            }
        }

        .row.opportunity {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 20px 0 0 10px;
            padding-bottom: 24px;

            .active, .brands {
                width: 49%;
                border-radius: 10px;
                border: solid 2px #dbdce0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 20px 0;

                .value {
                    height: auto;
                    font-size: 60px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    
                    text-align: center;
                    color: #091f5a;
                }

                .label {
                    font-size: 18px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    
                    text-align: center;
                    color: #2e2f32;
                }
            }
        }

        .brand-tracking-button {
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            
            text-align: center;
            color: #091f5a;
            padding: 4px 10px 3px;
            border-radius: 6px;
            border: solid 1px #091f5a;
            cursor: pointer;
            display: inline-block;
        }

        .row.brands {
            padding: 0 16px;
            padding-top: 24px;
            
            .brand {
                div {
                    color: #fff;
                    background-color: #031434;
                    font-weight: bold;
                    font-size: 16px;
                    padding: 8px 0;
                    width: 60px;
                    height: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%; 

                    &.selected {
                        background-color: #7888a5;
                    }
                }

                &.left {
                    display: flex;
                    justify-content: start;
                }
    
                &.center {
                    display: flex;
                    justify-content: center;
                }
    
                &.right {
                    display: flex;
                    justify-content: end;
                }
            }   

            .brand {
                div {
                    color: #fff;
                    background-color: #031434;
                    font-weight: bold;
                    font-size: 16px;
                    padding: 8px 0;
                    width: 60px;
                    height: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%; 

                    &.selected {
                        background-color: #7888a5;
                    }
                }

                &.left {
                    display: flex;
                    justify-content: start;
                }
    
                &.center {
                    display: flex;
                    justify-content: center;
                }
    
                &.right {
                    display: flex;
                    justify-content: end;
                }
            }   
        }

        .row.labels {
            padding: 0 16px;

            .label {
                div {
                    color: #fff;
                    font-size: 16px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.13;
                    
                    text-align: center;
                    color: #031434;
                    width: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%; 

                    &.selected {
                        background-color: #7888a5;
                    }
                }

                &.left {
                    display: flex;
                    justify-content: start;
                }
    
                &.center {
                    display: flex;
                    justify-content: center;
                }
    
                &.right {
                    display: flex;
                    justify-content: end;
                }
            }   

            .brand {
                div {
                    color: #fff;
                    background-color: #031434;
                    font-weight: bold;
                    font-size: 16px;
                    padding: 8px 0;
                    width: 60px;
                    height: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%; 

                    &.selected {
                        background-color: #7888a5;
                    }
                }

                &.left {
                    display: flex;
                    justify-content: start;
                }
    
                &.center {
                    display: flex;
                    justify-content: center;
                }
    
                &.right {
                    display: flex;
                    justify-content: end;
                }
            }   
        }

        .tip {
            width: 60%;
            margin: 0px auto;
            padding: 16px;
            background-color: #031434;
            color: #fff;
            border-radius: 16px;
            font-size: 18px;
            position: relative;
        }

        .tip:after {
            content: "";
            position: absolute;
            top: 50px;
            left: 50%;
            margin-left: -20px;
            width: 0;
            height: 0;
            border-top: solid 16px #031434;
            border-left: solid 16px transparent;
            border-right: solid 16px transparent;
        }

        .row.labels {
            color: #000;
            font-weight: $font-medium;

            font-size: 12px;
            min-height: 24px;
            align-items: center;
            justify-content: center;
            padding: 4px 16px;     
        }
    }

    .tier-bar-label {
        top: 24px;
    }

    .tier-chart-container .tier-chart .tier-chart-item.target .target-box .target .value {
        padding: 2px 20px;
    }

    .tier-chart-container .tier-chart .tier-chart-item.target .tier-bar-label-up {
        padding: 2px 10px;
    }

    .legend-items {
        display: flex;
        flex-direction: row;
        padding-bottom: 10px;
        justify-content: end;

        .legend-item {
            margin: 0;
            display: flex;
            align-items: center;
            margin-right: 15px;

            .legend-title {
                font-size: 10px;
                font-weight: $font-medium;

                color: #8792B1;
                margin-left: 5px;
                margin-top: 2px;
            }

            .legend-color {
                width: 10px;
                height: 10px;
                border-radius: 2px;

                &.active {
                    background-color: #d1e7d7;
                }

                &.from {
                    background-color: #fce5e5;
                }
            }
        }
    }
}

#portfolio-rating {

    div.box {
        text-decoration: none;
        line-height: 9px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;

        .left {
            flex-direction: column;
            display: flex;
            width: 100%;
        }

        .right {
            display: flex;
            flex-direction: column;
            width: 23%;
            align-items: flex-start;

            .data-box {
                text-decoration: none;
                border: 1px solid $border-primary-color;
                background-color: #fff;
                border-radius: 7px;
                width: 290px;

                flex-direction: column;
                position: relative;

                &.data-chart {
                    height: 444px;

                    .hover-area {
                        position: absolute;
                        top: 16px;
                        right: 16px;
                    }
                }

                &.data-text {
                    margin-top: 18px;
                    height: 51px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    div.simple-data-row {
                        display: flex;
                        width: 92%;
                        align-items: center;
                        justify-content: space-between;

                        .label {
                            font-size: 12px;
                            color: #8792B1;
                            width: auto;
                            font-weight: bold;
                        }

                        .value {
                            font-size: 14px;
                            font-weight: bold;
                            margin-right: 15px;
                        }
                    }
                }
            }
        }

        .box-row {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }

    .chart-container {
        margin: 0px auto;
        width: 220px;
        text-align: center;
    }

    .chart-labels {
        margin: 0 16px;
    }

    div.data-row {
        border: 1px solid $border-primary-color;
        background-color: white;
        border-radius: 7px;
        height: 30px;
        width: 100%;
        display: flex;
        margin: 12px 0;
        align-items: center;
        justify-content: space-between;

        .square {
            width: 10px;
            height: 10px;
            border-radius: 2px;
            margin: 8px 5px 10px 10px;
        }

        .label {
            display: flex;
            font-size: 10px;
            font-weight: bold;
            align-items: center;
        }

        .value {
            display: flex;
            font-size: 12px;
            font-weight: bold;
            margin-right: 10px;
        }
    }
}

// OK
#total-provided {

    .tier-chart-box {
        margin: 0 12px;
    }

    .data-container {
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
        justify-content: start;
        border-radius: 10px;
        border: 1px solid $border-primary-color;
        border-collapse: collapse;
        background-color: #fff;

        &.horizontal {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    .tier-chart-box {
        width: 100%;
    }

    .brand-data-box {
        width: 100%;
    }

    h3 {
        font-size: 14px;
        color: $font-primary-color;
        font-weight: $font-medium;

        display: flex;
        align-self: start;
        margin-left: 20px;
        padding: 12px 0 8px 0;
    }

    .box-container {
        display: flex;
        align-self: center;
        height: auto;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0 16px 16px 16px;
        justify-content: space-between;
        align-items: start;
    }

    .half-box {
        width: 49%;
        display: flex;

        &.column {
            flex-direction: column;
        }
    }

    .left-box {
        width: 65%;
        height: 307px;
    }

    .right-box {
        // display: props.hasData ? 'flex' : 'none',
        flex-direction: column;
        align-items: start;
        width: 35%;
        height: 307px;
        background-color: #fff;
        border-left: 1px solid $border-primary-color;
        border-radius: 10px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding: 0 16px;

        h3 {
            margin-left: 0;
        }
    }

    .simple-table {
        align-self: center;
        overflow: hidden;
        width: 100%;
        font-size: 12px;
        border-collapse: collapse;
        border-radius: 10px;
        border-style: hidden;
        box-shadow: 0 0 0 1px $border-primary-color;

        tr {
            height: 24px;
            display: inline-block;
            width: 100%;
            margin: 4px 0;
            padding-top: 4px;
        }

        th {
            color: #8792B1;
            border-collapse: collapse;
            text-align: left;
            font-weight: $font-medium;
            padding-left: 25px;
            display: block;
            float: left;
            width: 80%;
        }

        td {
            width: 20%;
            padding-right: 25px;
            font-size: 12px;
            font-weight: bold;
            text-align: right;
        }
    }
}

// OK
#performance {
    .data-container {
        margin: 0 auto;
        padding: 16px 0;
        display: flex;
        flex-direction: row;
    }
}

#rebates,
.rebates {

    .tier-chart-box {
        margin: 0 12px;
    }

    .value-box {
        padding: 18px 24px !important;
    }

    h3 {
        display: flex;
        align-self: start;
        margin-left: 12px;
        padding: 12px 0 8px 0;

        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        
        text-align: left;
        color: #031434;
    }

    div.separator {
        height: 32px;
    }

    div.box {
        text-decoration: none;
        border: 1px solid $border-primary-color;
        border-radius: 10px;
        line-height: 9px;
        margin: 16px;
        padding: 8px 12px;
        background-color: #fff;
    }

    .data-container {
        margin: 0 auto;
        padding: 16px 0;
        display: flex;
        flex-direction: row;
    }

    .chart-container {
        border: 1px solid $border-primary-color;
        border-radius: 10px;
        height: auto;
        margin: 20px 0;
        display: flex;
        padding: 28px 16px;
        flex-direction: column;
        align-items: center;
    }

    .data-row {
        display: flex;
        align-self: center;
        height: auto;
        width: '100%';
    }

    .simple-table {
        // overflow: hidden;
        width: 100%;
        font-size: 12px;
        height: 100%;
        border-radius: 10px;
        // border-style: hidden;
        border-collapse: separate !important;
        border: solid 1px #DCE0EB;

        tr {
            height: 24px;
            display: inline-block;
            width: 100%;
            margin: 4px 0;
            padding-top: 4px;
        }

        th {
            min-width: 200px;
            color: #8792B1;
            border: none;
            border-collapse: collapse;
            border-radius: 10px;
            text-align: left;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            
            text-align: left;
            color: #031434;
            padding-left: 12px;
            display: block;
            float: left;
        }

        td {
            width: 20%;
            min-width: 100px;
            text-align: right;
            border: none;
            padding-right: 16px;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            
            text-align: right;
            color: #031434;
        }
    }
}

// OK
#el360panel {

    .row.items {
        justify-content: center;
        padding: 16px 0 0 0;
    }

    .divider {
        margin: 16px 0;
        background-color: $border-primary-color;
        height: 1px;
    }

    .item {
        display: flex;
        justify-content: center;
        height: 216px;
        width: auto;

        .box {
            width: 270px;
            height: 190px;
            background-color: #fff;
            border: 1px solid $border-primary-color;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 15px;
            line-height: 45px;
        }

        .title {
            font-size: 18px;
            font-weight: bold;
            margin: 0;
        }

        .text {
            font-size: 10px;
            border-width: 1px;
            border-style: solid;
            border-radius: 14px;
            width: 80px;
            height: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
        }

        .info {
            margin-left: 0;
            display: flex;
            flex-direction: column;
            position: relative;
            align-content: center;
        }

        span.hovertip {
            width: auto;
            background-color: $tooltip-background-color;
            color: $tooltip-foreground-color;
            text-align: center;
            border-radius: 10px;
            padding: 10px;
            position: absolute;
            z-index: 1;
            margin-top: -24px;
            margin-left: 0;
            font-size: 12px;
        }
    }

    .data-panel .head {
        background-color: #fff;
    }
}

#results {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 20px;

    /*
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    //width: '100%',
    minWidth: '500px',
    //minHeight: '80.7vh',
    marginTop: '160px',
    overflow: 'hidden',
    */

    .item {
        width: 100%;
        overflow: hidden;

        a {
            text-decoration: none;
            border: 1px solid #dbdce0;
            background-color: #fff;
            display: flex;
            align-items: flex-start;
            margin-bottom: 24px;
            padding: 16px 0;
            border-radius: 10px;
            border: solid 1px #dbdce0;

            &:hover {
                border: 1px solid $luxottica-blue-2;
                // box-shadow: 0px 10px 40px -8px #aeaeae;
                cursor: pointer;
            }

            .icon {
                margin: 0 20px;
                width: 48px;
                height: 48px;
                border: none;
                border-radius: 24px;
                background-color: #091f5a;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .detail {
            text-align: left;

            div.email {
                display: flex;
                flex-direction: row;
                justify-content: start;
                align-items: center;
    
                .text {
                    display: flex;
                    justify-content: start;
                    padding-left: 5px;
                    align-items: center;
                    margin-left: 4px;
                    font-size: 12px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    
                    text-align: left;
                    color: #091f5a;
                }
            }

            .name {
                padding: 0 0 4px 0;
                text-align: left;
                font-size: 22px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                
                text-align: left;
                color: #2e2f32;
            }

            .customer {
                display: flex;
                padding-bottom: 6px;
                margin-top: -4px;

                .label {
                    font-size: 12px;
                    padding: 4px 12px;
                    border-radius: 30px;
                    margin-right: 10px;
                    font-weight: bold;
                    border-width: 1px;
                    border-style: solid;
                }
            }

            div.address {
                display: flex;
                flex-direction: row;
                justify-content: start;
                align-items: center;

                .text {
                    padding-left: 7px;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    
                    text-align: left;
                    color: #2e2f32;
                }
            }
        }
    }
}

#no-results {
    margin-top: 10%;
}

#pager {
    margin-top: 20px;
    justify-content: center;

    button {
        cursor: not-allowed;
        text-decoration: none;
        color: #aaa;
        padding: 0px 16px;
        pointer-events: none;
        background-color: #fff;
        border: none;
        font-weight: normal;

        &.enabled {
            cursor: pointer !important;
            pointer-events: all;
            color: #666;
            font-weight: bold;
        }
    }
}

#last-update {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    display: flex;
    justify-content: center;
    color: #000;
    padding: 8px 0;
    width: 100%;
}

#loader {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: $max-zindex;
    top: 0;
    left: 0;
    flex-direction: column;
}

.data-status {
    text-decoration: none;
    border: 1px solid #E5E5E5;
    background-color: white;
    border-radius: 7px;
    width: 100%;
    margin-top: 16px;
    height: 276px;
    flex-direction: column;
    position: relative;

    .needed {
        position: absolute;
        right: 10px;
        top: 16px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        
        text-align: right;
        color: #031434;
    }

    .separator {
        width: 97%;
        height: 1px;
        background-color: #dbdce0;
        margin: 20px auto;
        margin-top: 40px;
    }

    img {
        margin-left: 20px;
        height: 24px;
        width: auto;
    }

    .title {
        position: absolute;
        top: 52px;
        left: 10px;
        /*
        font-size: 12px;
        font-weight: bold;
        */
        display: flex;
        align-items: center;
        // border: solid 1px #DCE0EB;
        // border-radius: 6px;
        padding: 6px 10px 6px 10px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        
        text-align: left;
        color: #031434;
    }

    .current {
        position: absolute;
        top: 16px;
        left: 10px;
        font-size: 12px;
        font-weight: normal;
        display: flex;
        align-items: center;
        padding: 0 10px;
    }

    .trending {
        position: absolute;
        top: 40px;
        right: 10px;
        font-size: 12px;
        font-weight: bold;
        display: flex;
        align-items: center;
        padding: 0 10px;
    }

    .rating {
        font-size: 12px;
        font-weight: bold;
        padding-left: 10px;
    }
}

.data-card {
    text-decoration: none;
    margin-right: 16px;
    flex-direction: column;
    width: 100%;
    position: relative;
    display: flex;
    padding: 20px;
    border-radius: 10px;
    border: solid 1px #dbdce0;
    background-color: #fff;

    &:last-child {
        margin-right: 0;
    }

    .iconBox {
        width: 52px;
        height: 52px;
        min-width: 52px;
        min-height: 52px;
        border: none;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0 0 20px;
    }

    .valueBox {
        font-size: 30px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        
        text-align: left;
        color: #031434;
        padding-bottom: 14px;
        padding-top: 16px;
    }

    .infoBox {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        
        text-align: left;
        color: #031434;
        padding: 5px 0;
    }

    .infoBlock {
        position: absolute;
        top: 16px;
        right: 16px;
    }

    .scalingBox {
        display: flex;
        padding-top: 22px;

        .scaling {
            display: flex;
            justify-content: center;
            align-items: center;
            // height: 48px;

            .scalingIconBox {
                width: 18px;
                height: 18px;
                min-width: 18px;
                min-height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50px;
                margin-right: 8px;
            }
        }

        .info {
            font-size: 12px;
            font-weight: bold;
        }

        .value {
            font-size: 12px;
            // color: isInfo ? (isScaling ? mapColors.theme.success[0] : mapColors.theme.danger[0]) : '#8A9DAF',
            // color: #031434 !important;
            font-weight: normal;
            margin-right: 4px;
            line-height: 12px;
        }
    }
}

.data-card,
.data-box {
    .hover {
        margin-left: 5px;
        display: flex;
        flex-direction: column;
        position: relative;
        align-content: center;
        align-items: center;
        justify-content: center;
    }

    .hovertip {
        width: auto;
        min-width: 200px;
        background-color: $tooltip-background-color;
        color: $tooltip-foreground-color;
        text-align: center;
        border-radius: 10px;
        padding: 10px;
        position: absolute;
        z-index: 999999;
        margin-top: 0;
        margin-left: 0;
        font-size: 11px;
        max-width: 200px;
        line-height: 11px;
        top: -68px;
    }
}

.data-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid $border-primary-color;
    border-radius: 10px;

    .head {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 100%;
        display: flex;
        height: 52px;
        justify-content: space-between;
        position: relative;

        h2 {
            padding: 12px 16px;
            font-size: 18px;
            color: $font-primary-color;
            font-weight: $font-medium;
            display: flex;
            align-items: center;
            margin: 0;

            .hover {
                margin-left: 5px;
                display: flex;
                flex-direction: column;
                position: relative;
                align-content: center;
                align-items: center;
                justify-content: center;
            }

            .hovertip {
                width: auto;
                min-width: 200px;
                background-color: $tooltip-background-color;
                color: $tooltip-foreground-color;
                text-align: center;
                border-radius: 10px;
                padding: 10px;
                position: absolute;
                z-index: 999999;
                margin-top: 0;
                margin-left: 0;
                font-size: 11px;
                max-width: 200px;
                line-height: 11px;
                top: -60px;
            }

            .date-label {
                background-color: #DEE6F7;
                width: auto;
                text-align: center;
                border-radius: 6px;
                margin-left: 17px;
                padding: 6px 10px;
                font-size: 12px;
                font-weight: bold;
                color: $font-tertiary-color;
            }
        }

        .expand {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 18px;
            right: 24px;
            // transform: props.collapsed ? 'rotate(180deg)' : 'rotate(0deg);
            transition-duration: 0.8s;
            transition-property: transform;
            cursor: pointer;
        }

        button.export {
            border: 1px solid $border-primary-color;
            border-radius: 8px;
            height: 36px;
            width: 135px;
            background-color: #fff;
            margin: 8px 56px 8px 0;
            font-size: 12px;
            font-weight: $font-medium;

            ;
            color: $font-tertiary-color;
            align-self: end;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            text-decoration: none;

            span {
                color: #2E7D32;
                font-weight: $font-medium;

                font-size: 14px;
                cursor: 'pointer';
            }
        }
    }

    .no-data {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 0;
        font-size: 12px;
        color: #999;
    }
}

.dx-widget {
    color: #7888A5;
    font-weight: $font-medium;
}

.dx-datagrid {
    color: $font-primary-color;
}

.dx-datagrid .dx-row-alt>td,
.dx-datagrid .dx-row-alt>tr>td {
    background-color: #fff;
    border-top: none;
    border-bottom: none;
}

.dx-pages .dx-texteditor-input {
    margin: 0;
    padding-top: 10px;
    background: $luxottica-blue-4;
    color: $luxottica-blue-2;
    border: 1px solid $luxottica-blue-2;
    font-size: 1em;
    border-radius: 6px;
    min-height: 26px;
    height: 26px;
}

.dxc-labels {
    margin-left: -10px;

    rect {
        rx: 10 !important;
    }

    text  {
        font-family: $default-font !important;
        font-size: 12px !important;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        
        text-align: right;
        color: #031434;
        rx: 10 !important;
    }
}

.expandable {
    width: 100%;
    background-color: $box-background;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: none;
    border-top: 1px solid $border-primary-color;
    flex-direction: column;
    background-color: #fff;

    &.boxed {
        padding: 16px;
    }

    &.expanded {
        display: flex;
        position: relative;
    }

    &.horizontal {
        flex-direction: row;
    }
}

.horizontal {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 0px auto;
}

.vertical {
    flex-direction: column;
}

.vertical-divider {
    height: 16px;
}

.message {
    font-size: 10px;
    color: #000;
    padding: 10px 0 0 20px;
    font-weight: bold;
    margin-top: -2px;
}

.note {
    position: absolute;
    left: 33px;
    bottom: 33px;
    font-size: 12px;
    color: #7888A5;
}

.data-box {
    text-decoration: none;
    border: 1px solid $border-primary-color;
    background-color: #fff;
    border-radius: 8px;
    // width: 290px;
    // flex-direction: column;
    position: relative;
    padding: 0px 8px;
}

.value-box {
    border: 1px solid $border-primary-color;
    // margin: '0 10px;
    // marginTop: isQuarterly ? '-10px' : '20px;
    padding: 18px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    border-radius: 10px;
    width: 100%;

    &.potential-rebate {
        margin-top: 64px;
    }
}

.text-label {
    font-size: 14px;
    color: #8792B1;
    width: 150px;
    font-weight: bold;
}

.text-value {
    font-size: 18px;
    font-weight: bold;
    // margin: '9px 15px 9px 78px',
}

.show {
    display: flex !important;
}

.hide {
    display: none !important;
}

.search-box {
    // padding: 0 16px;
    display: flex;
    justify-content: center;

    form {
        align-items: center;
        padding: 0 16px;
        position: relative;
        width: 75%;
        max-width: 400px;

        input {
            font-weight: $font-medium;

            width: 100%;
            height: 40px;
            border-radius: 20px;
            border: solid 1px #dbdce0;
            position: relative;
            padding-left: 23px;

            &:focus {
                outline: none;
                font-size: 16px;
                color: #091f5a;
            }

            &:not(:focus) {
                outline: none;
                font-size: 16px;
                color: #091f5a;
            }

            &::placeholder {
                font-size: 16px;
                color: #8792B1;
            }
        }

        button {
            height: 34px;
            width: 34px;
            background: #031434;
            border-radius: 50px;
            border: none;
            z-index: 2;
            position: absolute;
            display: inline-grid;
            justify-content: center;
            margin-right: 2px;
            align-items: center;
            cursor: pointer;
            right: 18px;
        }
    }

    table.simple {
        align-self: center;
        overflow: hidden;
        width: 100%;
        font-size: 12px;
        border-collapse: collapse;
        border-radius: 10px;
        border-style: hidden;
        box-shadow: 0 0 0 1px $border-primary-color;

        th {
            height: 28.5px;
            min-width: 200px;
            color: #8792B1;
            border: 1px solid $border-primary-color;
            border-collapse: collapse;
            border-radius: 10px;
            text-align: left;
            font-weight: $font-medium;

            padding-left: 25px;
        }

        td {
            min-width: 100px;
            text-align: center;
            font-size: 12px;
            border: 1px solid $border-primary-color;
            font-weight: bold;
        }
    }
}

table.tier {
    // overflow: hidden;
    width: 100%;
    border-collapse: separate !important;
    border-spacing: 0;
    border-radius: 10px;
    // border-style: hidden;
    border: solid 1px $border-primary-color;
    margin-bottom: 20px;

    th {
        min-width: 100px;
        color: #8792B1;
        border: 1px solid $border-primary-color;
        border-collapse: collapse;
        border-radius: 10px;
        font-weight: $font-medium;

        font-size: 12px;
    }

    td {
        min-width: 80px;
        padding: 10px 0;
        text-align: center;
        color: $font-primary-color;
        font-size: 12px;
        // border: 1px solid $border-primary-color;
        font-weight: bold;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        
        text-align: center;
        color: #031434;
        border: none;

        &.gray {
            color: #7888a5;
        }
    }
}

.ml-0 {
    margin-left: 0;
}

.mr-0 {
    margin-right: 0;
}

.px-15 {
    padding-right: 15px;
    padding-left: 15px;
}

.py-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.content-prelogin {
    background-image: url(images/Background_prelogin_smartphone.svg);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
    width: 100%;
    height: 100%;
}

.box-general-prelogin {
    background-color: #fff;
    box-shadow: 0px 3px 40px rgb(0 0 0 / 5%);
    border-radius: 20px;
    transition: .3s;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.box-general-prelogin .logo img {
    width: 160px;
}

.box-tasti-seleziona {
    margin-top: 20px;
}

.tasto-seleziona {
    background-color: #fff;
    border: 1px solid var(--color-border-gray);
    box-shadow: 0px 3px 20px rgb(0 0 0 / 5%);
    padding-top: 30px;
    padding-bottom: 30px;
    cursor: pointer;
    border-radius: 10px;
    text-align: center;
    transition: .3s;
}

.tasto-seleziona .icona-seleziona img.img-gray {
    width: 40px;
    margin-bottom: 20px;
    display: block;
}

.tasto-seleziona .icona-seleziona img.img-blue {
    width: 40px;
    margin-bottom: 20px;
    display: none;
}

.tasto-seleziona:hover {
    background-color: var(--color-primary-light);
    border: 1px solid var(--color-primary);
    box-shadow: 0 15px 20px rgb(0 0 0 / 20%);
    transform: translate(0, -4px);
    transition: box-shadow .35s ease-out, transform .3s ease-out, opacity .2s ease-out, -webkit-transform .3s ease-out;
}

.tasto-seleziona:hover .icona-seleziona img.img-gray {
    width: 40px;
    margin-bottom: 20px;
    display: none;
}

.tasto-seleziona:hover .icona-seleziona img.img-blue {
    width: 40px;
    margin-bottom: 20px;
    display: block;
}

.tasto-seleziona h5 {
    font-size: 18px;
    font-weight: $font-medium;

    color: var(--color-text-primary);
    margin-bottom: 0;
    text-decoration: none;
}

.tasto-seleziona:hover .pallino-selezionato,
.tasto-seleziona.multi.selected .pallino-selezionato {
    display: block;
}

.tasto-seleziona .pallino-selezionato {
    display: none;
    position: absolute;
    bottom: -18px;
    width: 36px;
    height: 36px;
    border-radius: 80px;
    background-color: var(--color-primary);
    transition: .3s;
    padding: 0;
}

.tasto-seleziona .pallino-selezionato img {
    font-size: 14px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    color: #fff;
}

.content-prelogin .blu-text {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
    color: var(--color-text-primary);
}

.content-prelogin .blu-text span {
    color: var(--color-primary);
}

.footer {
    margin-top: auto;
}

.footer .row {
    --bs-gutter-x: 0;
}

.footer img {
    width: 300px;
}

@media (min-width: 576px) {

    .px-md-30 {
        padding-right: 30px;
        padding-left: 30px;
    }

    .py-md-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .tasto-seleziona {
        padding-top: 50px;
        padding-bottom: 50px;
    }

}

@media (min-width: 768px) {

    .px-md-30 {
        padding-right: 30px;
        padding-left: 30px;
    }

    .py-md-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .box-general-prelogin {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .box-general-prelogin .logo img {
        width: 200px;
    }

    .content-prelogin .blu-text {
        font-size: 22px;
    }

    .tasto-seleziona h5 {
        font-size: 20px;
    }

    .footer img {
        width: 700px;
    }

}

@media (min-width: 992px) {

    .px-lg-30 {
        padding-right: 30px;
        padding-left: 30px;
    }

    .py-lg-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .mt-lg-90 {
        margin-top: 90px;
    }

    .box-general-prelogin {
        margin-top: 70px;
        margin-bottom: 30px;
    }

    .content-prelogin {
        background-image: url(images/Background_prelogin.svg);
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 100%;
        width: 100%;
        height: 100%;
    }

    .box-general-prelogin {
        padding-top: 90px;
        padding-bottom: 90px;
    }

}

.nogutter {
    --bs-gutter-x: 0 !important;
}

.prelogin *,
.prelogin ::after,
.prelogin ::before {
    box-sizing: border-box;
}

.prelogin h5 {
    display: block;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.claim-intro {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 520px;
    font-size: 22px;
    font-style: italic;
    white-space: pre-wrap;
}

.claim {
    margin: 22px 8px 0px 8px;
    color: $font-primary-color;
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
}

.cover-title {
    margin: 22px 8px 0px 8px;
    color: $font-primary-color;
    font-size: 22px;
    font-style: italic;
}

.dxc-chart {
    width: 100% !important;
}

.dx-gridbase-container {
    display: block;
    font-family: $default-font !important;
    border-radius: 10px !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    padding: 16px;
    background-color: $box-background;
    // width: 1215px !important;
    /* height: 465px !important; */
}

.dx-submenu,
.dx-overlay-content {
    font-family: $default-font !important;
}

.dx-datagrid-rowsview {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    
    text-align: left;
    color: #2e2f32;
}

.dx-datagrid-headers {
    text-transform: uppercase !important;
    font-size: 14px !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    
    text-align: left;
    color: #7888a5 !important;
}

.dx-datagrid-pager {
    border-top: none !important;
    font-family: $default-font !important;
    font-size: 11px !important;
    background-color: #fff !important;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-top: 10px;
    border: 1px solid $border-primary-color;
    border-top: none !important;
}

.dx-pager .dx-light-pages {
    margin-top: 4px;
}

.dx-datagrid-borders>.dx-datagrid-filter-panel,
.dx-datagrid-borders>.dx-datagrid-headers {
    border-radius: 10px 10px 0 0 !important;
    border: 1px solid $border-primary-color;
    background-color: #fff;
}

.dx-datagrid-borders>.dx-datagrid-rowsview,
.dx-datagrid-borders>.dx-datagrid-total-footer {
    border: 1px solid $border-primary-color;
    border-top: none;
}

/*
  .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    border: none !important;
  }
  */

.dx-datagrid .dx-row-alt>td,
.dx-datagrid .dx-row-alt>tr>td {
    background-color: #f7f8f9;
}

.dx-widget input,
.dx-widget textarea {
    font-family: $default-font !important;
    font-size: 12px;
}

.dx-scrollable-native.dx-scrollable-native-generic .dx-scrollable-content {
    background-color: #fff;
}

.dx-treeview-item,
.dx-dropdowneditor,
.dx-popup-wrapper,
.dx-button-content,
.dx-calendar-body * {
    font-family: $default-font;
    font-size: 12px;
}

.dx-calendar-body * {
    font-size: 11px;
}

.dx-calendar-navigator .dx-calendar-caption-button {
    font-size: 13px;
}

.dx-calendar-body thead * {
    font-size: 10px;
}

.dx-dropdowneditor.dx-dropdowneditor-field-clickable,
.dx-dropdowneditor.dx-dropdowneditor-field-clickable .dx-texteditor-input {
    font-family: $default-font;
}

.dx-treeview-item .dx-treeview-item-content span {
    font-family: $default-font;
}

.dx-popup-content {
    padding: 8px 2px;
}

.admin_rebates_edit form input,
.admin_rebates_edit form select,
.dx-dropdowneditor.dx-dropdowneditor-field-clickable,
.dx-dropdowneditor.dx-dropdowneditor-field-clickable .dx-texteditor-input,
.dx-datebox:not(.dx-datebox-native).dx-auto-width .dx-texteditor-input,
.dx-datebox:not(.dx-datebox-native):not(.dx-texteditor-empty).dx-auto-width .dx-texteditor-input {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal !important;
    
    color: #091f5a;
}

.dx-checkbox-icon {
    width: 16px;
    height: 16px;
}

.dx-checkbox-checked .dx-checkbox-icon {
    font: 12px/1em DXIcons;
    background-color: $checkbox-selected-background-color;
    color: $checkbox-selected-foreground-color;
    text-align: center;
}

.dx-switch {
    width: 36px;
    height: 24px;
}

.dx-switch-container {
    padding: 0 2px;
    height: 22px;
    background: #fff;
    border-radius: 9px;
}

.dx-switch-handle {
    width: 16px;
    height: 16px;
}

.dx-placeholder {
    font-family: $default-font;
    font-size: 12px;
}

div.col.multiple {
    .dx-texteditor.dx-editor-outlined {
        width: 49%;
        float: left;
    }

    .dx-texteditor.dx-editor-outlined:first-of-type {
        margin-right: 1%;
    }
}

.dx-texteditor.dx-editor-outlined {
    border: inherit;
}

.dx-switch-handle::before {
    border-radius: 50%;
}

.dx-datagrid-content .dx-datagrid-table .dx-row>td,
.dx-datagrid-content .dx-datagrid-table .dx-row>tr>td {
    vertical-align: middle;
}

.dx-datagrid .dx-row>td {
    padding: 10px;
}

.dx-dropdownlist-popup-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item-content {
    font-size: 12px;
}

.dx-popup-flex-height .dx-popup-content {
    padding: 4px 2px;
}

.bars {
    display: flex;
    align-items: center;
    width: 100%; //1122px;
    position: relative;
    padding: 16px 0;
    font-weight: $font-medium;


    .bar {
        height: 26px;
        border-radius: 30px;
        display: flex;
        justify-content: right;
        align-items: center;
        position: absolute;
        z-index: 300;
        min-width: 26px;

        &.z100 {
            z-index: 100;
        }

        &.z200 {
            z-index: 200;
        }

        &.z300 {
            z-index: 300;
        }

        .indicator {
            height: 26px;
            border-radius: 30px;
            display: grid;
            justify-content: right;
            align-items: center;
            position: absolute;
            z-index: 600;
            min-width: 26px;

            div {
                height: 16px;
                width: 16px;
                border-radius: 16px;
                border: solid 3px #fff;
                display: grid;
                z-index: 610;
                position: relative;
                right: 2px;
            }
        }

        .target {
            display: inline-block;
            justify-content: right;
            align-items: start;
            position: absolute;
            z-index: 100;
            margin-left: 50%;
            top: 22px;
            font-size: 11px;
            color: rgb(135, 146, 177);
            min-width: 200px;
            padding-right: 10px;
            height: 20px;
            padding-top: 10px;

            &.top {
                text-align: right;
                top: -16px;
                color: rgb(135, 146, 177);
                padding-right: 5px;
                right: 10px;
                padding-top: 2px;
            }
        }

        .value {

            span {
                padding-left: 5px;

                &.right {
                    padding-right: 5px;
                }
            }

            &.bottom-left {
                // width: `${value}%`,
                display: inline-block;
                justify-content: left;
                align-items: center;
                position: absolute;
                z-index: 100;
                left: 10px;
                top: 32px;
                font-size: 11px;
                color: rgb(135, 146, 177);
                min-width: 200px;
            }
        }
    }
}

.alert {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    // box-shadow: 0px -2px 16px 7px rgba(0, 0, 0, 0.13);
    z-index: 10;
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translate(-50%, 0%);
    height: auto;

    .content {
        width: 795px;
        display: flex;
        font-weight: $font-medium;

        font-size: 16px;
        align-items: start;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        justify-content: space-between;

        .icon {
            position: absolute;
            left: 2px;
            top: 25px;
        }

        .text {
            display: flex;
            align-items: start;
            flex-direction: column;
            padding: 12px 20px;
        }

        .announce {
            font-weight: $font-medium;

            text-align: left;
            display: flex;
            align-items: center;
            margin-left: 20px;
            margin-bottom: 8px;
        }

        .buttons {
            display: flex;
            align-items: center;
        }

        .read-more {
            width: 91px;
            height: 30px;
            border-radius: 8px;
            display: flex;
            margin-top: 10px;
            border: none;
            // background-color: utilityGetColorByVariant(variant).color;
            color: white;
            margin-left: 30px;
            cursor: pointer;
            padding: 6px 14px;

            p {
                font-size: 12px;
                font-weight: $font-medium;

            }
        }

        .read-less {
            border: none;
            background-color: transparent;
            margin: 16px;
            margin-top: 0;
            color: $font-tertiary-color;
            text-decoration: underline;
            font-weight: $font-medium;

            cursor: pointer;
        }

        .close {
            border: none;
            background: none;
            margin: 10px;
            margin-top: 10px;
            cursor: pointer;
        }
    }
}

.admin {
    .back {
        text-align: left;
        padding: 16px;

        a,
        a:link,
        a:hover,
        a:visited {
            display: block;
            padding-left: 28px;
            cursor: pointer;
            background-image: url(icons/admin/back.svg);
            background-position: left;
            background-repeat: no-repeat;
            background-size: contain;
            font-weight: bold;
            font-size: 14px;
            color: $font-primary-color;
            text-decoration: none;
        }
    }

    .customize_button {
        padding: 4px 8px;
        width: auto;
        background-color: $add-background-color;
        border-radius: 8px;

        a,
        a:link,
        a:hover,
        a:visited {
            padding-left: 22px;
            color: $add-foreground-color;
            cursor: pointer;
            background-image: url(icons/admin/add.svg);
            background-position: left;
            background-repeat: no-repeat;
            background-size: contain;
            text-decoration: none;
            font-weight: $font-medium;
            font-size: 14px;
        }
    }

    .customize_level_button {
        padding: 4px 8px;
        width: auto;
        background-color: $add-background-color;
        border-radius: 8px;
    
        a,
        a:link,
        a:hover,
        a:visited {
            padding-left: 22px;
            color: $add-foreground-color;
            cursor: pointer;
            background-image: url(icons/admin/add.svg);
            background-position: left;
            background-repeat: no-repeat;
            background-size: contain;
            text-decoration: none;
            font-weight: $font-medium;
            font-size: 14px;
        }
    }
    
    .customize_level_button.active {
        padding: 4px 8px;
        width: auto;
        background-color: #EEEEEE;
        border-radius: 8px;
        
        a,
        a:link,
        a:hover,
        a:visited {
            padding-left: 22px;
            color: #000;
            cursor: pointer;
            background-image: url(icons/admin/back.svg);
            background-position: left;
            background-repeat: no-repeat;
            background-size: contain;
            text-decoration: none;
            font-weight: $font-medium;
            font-size: 14px;
        }
    }
    .add {
        padding: 4px 8px;
        width: auto;
        background-color: $add-background-color;
        border-radius: 8px;

        a,
        a:link,
        a:hover,
        a:visited {
            display: inline-block;
            padding-left: 22px;
            color: $add-foreground-color;
            cursor: pointer;
            background-image: url(icons/admin/add.svg);
            background-position: left;
            background-repeat: no-repeat;
            background-size: contain;
            text-decoration: none;
            font-weight: $font-medium;

            font-size: 12px;
        }
    }

    .back_action_button {
        padding: 4px 8px;
        width: auto;
        background-color: white;
        border-radius: 8px;

        a,
        a:link,
        a:hover,
        a:visited {
            padding-left: 22px;
            color: black;
            cursor: pointer;
            background-image: url(icons/admin/back.svg);
            background-position: left;
            background-repeat: no-repeat;
            background-size: contain;
            text-decoration: none;
            font-weight: $font-medium;
            font-size: 14px;
        }
    }

    .refresh {
        padding: 4px 8px;
        width: auto;
        background-color: $action-background-color;
        border-radius: 8px;

        a,
        a:link,
        a:hover,
        a:visited {
            padding-left: 22px;
            color: $save-foreground-color;
            cursor: pointer;
            background-image: url(icons/admin/save.svg);
            background-position: left;
            background-repeat: no-repeat;
            background-size: contain;
            text-decoration: none;
            font-weight: $font-medium;
            font-size: 14px;
        }
    }

    button.add {
        padding: 0px 8px;
        width: auto;
        background-color: $add-background-color;
        border-radius: 8px;
        display: inline-block;
        padding-left: 22px;
        color: $add-foreground-color;
        cursor: pointer;
        background-image: url(icons/admin/add.svg);
        background-position: left;
        background-repeat: no-repeat;
        background-size: contain;
        text-decoration: none;
        font-weight: $font-medium;

        font-size: 12px;
        border: none;
    }

    .action {
        padding: 4px 8px;
        width: auto;
        background-color: $action-background-color;
        border-radius: 8px;

        a,
        a:link,
        a:hover,
        a:visited {
            padding-left: 22px;
            color: $action-foreground-color;
            cursor: pointer;
            background-image: url(icons/admin/refresh.svg);
            background-position: left;
            background-repeat: no-repeat;
            background-size: contain;
            text-decoration: none;
            font-weight: $font-medium;
            font-size: 14px;
        }
    }

    .save {
        padding: 4px 8px;
        width: auto;
        background-color: $save-background-color;
        border-radius: 8px;

        a,
        a:link,
        a:hover,
        a:visited {
            padding-left: 22px;
            color: $save-foreground-color;
            cursor: pointer;
            background-image: url(icons/admin/save.svg);
            background-position: left;
            background-repeat: no-repeat;
            background-size: contain;
            text-decoration: none;
            font-weight: $font-medium;
            font-size: 14px;
        }
    }

    .disabled {
        pointer-events: none;
        opacity: 0.25;
        cursor: default;
    }
}

.admin_rebates {
    min-width: 1100px;
    text-align: center;
    padding: 32px;

    a {
        font-weight: bold;
    }

    img.add {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 14px;
        right: 24px;
        cursor: pointer;
        border: solid 1px $border-primary-color;
        border-radius: 6px;
        padding: 4px;
        background-color: #fff;

        &.addrebate {
            margin-right: 32px;
        }
    }

    img.typeids {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 14px;
        right: 24px;
        cursor: pointer;
        border: solid 1px $border-primary-color;
        border-radius: 6px;
        padding: 4px;
        background-color: #718cbc;
    }

    img.edit {
        width: 24px;
        height: 24px;
        cursor: pointer;
        border: solid 1px $border-primary-color;
        border-radius: 6px;
        padding: 4px;
    }

    img.delete {
        width: 24px;
        height: 24px;
        cursor: pointer;
        border: solid 1px $border-primary-color;
        border-radius: 6px;
        padding: 4px;
    }
}

.admin_rebates_edit {
    text-align: center;
    padding: 32px;
    min-width: 1100px;

    form {
        border: solid 1px $border-primary-color;
        border-radius: 10px;

        input,
        select {
            border: solid 1px $border-primary-color;
            width: 100%;
            font-size: 12px;
            font-weight: normal;
            padding: 4px 4px;
        }

        .head {
            border-bottom: solid 1px $border-primary-color;

            h2 {
                padding: 12px;
                font-size: 14px;
                font-weight: bold;
                color: $font-primary-color;
                text-align: left;
                margin: 0;
            }
        }

        h3 {
            padding: 12px 0;
            font-size: 14px;
            font-weight: bold;
            color: $luxottica-blue-2;
            text-align: left;
        }

        label {
            font-size: 12px;
            font-weight: bold;
            color: $label-primary-color;
            text-align: left;
            display: flex;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        div.row {
            padding: 12px;
        }

        .addbutton {
            .add {
                margin-top: 16px;
                width: 32px;
                height: 32px;
                cursor: pointer;
                float: right;
            }
        }
    }

    .saveButton {
        padding: 32px 0;
        padding-right: 16px;
        justify-content: right;
    }

    .modal-body {
        padding: 0;

        .separator {
            margin-top: 12px;
            border-top: solid 1px $border-primary-color;
        }

        h6 {
            font-size: 14px;
            color: $font-primary-color;
            font-weight: $font-medium;
            display: flex;
            align-items: center;
            padding-bottom: 4px;
            padding-bottom: 0;
            margin-bottom: 0;
            padding-top: 12px;
        }

        .criteria_form {
            border-bottom: solid 1px $border-primary-color;
            margin-bottom: 12px;
            padding-bottom: 20px;
        }
    }

}

div.not-valid {

    label,
    div.title {
        color: $not-valid-color;
    }

    .checkboxselection,
    .admin_rebates_edit form input,
    .admin_rebates_edit form select,
    .dx-dropdowneditor.dx-dropdowneditor-field-clickable,
    .dx-dropdowneditor.dx-dropdowneditor-field-clickable .dx-texteditor-input,
    .dx-datebox:not(.dx-datebox-native).dx-auto-width .dx-texteditor-input,
    .dx-datebox:not(.dx-datebox-native):not(.dx-texteditor-empty).dx-auto-width .dx-texteditor-input,
    .dx-texteditor-input {
        border-color: $not-valid-color !important;
    }

    div.multiselection,
    div.boxed {
        border: solid 1px $not-valid-color !important;
        border-radius: 8px;
    }
}

.darkBlue {
    color: $luxottica-blue-2;
}

.lightBlue {
    color: $luxottica-blue-4;
}

.chart-title {
    position: relative;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    
    text-align: left;
    color: #031434;
}

.chart-bullet-box {
    position: relative;

    .bar-tooltip {
        position: absolute;
        z-index: 999999;
        width: 150px;
        background-color: $font-primary-color;
        color: white;
        padding: 16px;
        font-size: 12px;
        justify-content: center;
        border-radius: 8px;
        font-weight: bold;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 10%);
        line-height: 11px;
        display: flex;
        top: -64px;
        left: 4px;

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            border: 10px solid transparent;
            border-top-color: $font-primary-color;
            border-bottom: 0;
            margin-left: -10px;
            margin-bottom: -10px;
        }
    }

    .status {
        position: absolute;
        width: 86%;
        left: 7%;
        right: 7%;
        height: 100px;
        z-index: 10;
        top: 40px;
        display: flex;
        flex-direction: row;
        

        .status-item {
            position: absolute;
            width: 120px;
            height: 110px;
            z-index: 50;
            /*
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            */
            display: block;
            text-align: center;
            padding-top: 7px;

            img {
                width: 60px;
                height: 60px;
                margin: 0;
                
                /*&.big {
                    width: 75px;
                    height: 75px;
                }*/
            }

            .name {
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                
                text-align: center;
                color: #031434;
            }

            @for $i from 1 through 6 {
                &:nth-child(#{$i}) {
                    left: calc(((#{$i} - 1) * 25%) - 60px);
                }
            }

            .goal {
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                
                text-align: center;
                color: #7888a5;
                padding: 10px 0;
            }
        }
    }

    .progress-box {
        position: absolute;
        width: 86%;
        left: 7%;
        right: 7%;
        height: 10px;
        z-index: 100;
        top: 149px;

        .progress {
            position: absolute;
            z-index: 40;
            width: 12px;
            height: 12px;
            background-color: #031434;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: -6px;
            margin-top: 1px;

            .indicator {
                height: 8px;
                width: 8px;
                background-color: #031434;
                border-radius: 50%;
            }
        }
    }

    .bars {
        position: absolute;
        width: 86%;
        left: 7%;
        right: 7%;
        height: 10px;
        z-index: 10;
        top: 140px;

        .main-bar {
            position: absolute;
            width: 100%;
            height: 6px;
            background-color: #7888a5;
            z-index: 20;
            border-radius: 3px;
        }

        .progress-bar {
            position: absolute;
            height: 6px;
            background-color: #031434;
            z-index: 30;
            border-radius: 3px;
        }
    }

    .target-box {
        position: absolute;
        width: 86%;
        left: 7%;
        right: 7%;
        height: 40px;
        z-index: 60;
        top: 160px;

        .target {
            position: absolute;
            width: 200px;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            // left: calc(20% - 100px);
            top: 10px;

            /*
            .line {
                background-color: #f00;
                width: 4px;
                height: 32px;
                background-color: #031434;
            }
            */

            .value {
                // min-width: 140px;
                width: auto;
                height: auto;
                display: flex;
                background-color: #031434;
                border-radius: 16px;
                padding: 8px 12px;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                
                color: #fff;
                align-items: center;
                justify-content: center;

                /*
                &.leftline {
                    // margin-left: 100px;
                }

                &.rightline {
                    // margin-right: 100px;
                }
                */

                &:after,
                &:before {
                    bottom: 100%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                }

                &:after {
                    border-width: 6px;
                    left: 50%;
                    margin-left: -6px;
                }

                &:before {
                    // border-color: rgba(113, 158, 206, 0);
                    border-bottom-color: #031434;
                    border-width: 6px;
                    left: 50%;
                    margin-left: -6px;
                }
            }
        }
    }

    .goals-box {
        position: absolute;
        width: 86%;
        left: 7%;
        right: 7%;
        height: 50px;
        z-index: 30;
        top: 173px;

        .goals {
            position: absolute;
            width: 100%;
            height: 50px;
            z-index: 40;

            .goal {
                position: absolute;
                width: 60px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .tick {
                    border-radius: 8px;
                    width: 4px;
                    height: 26px;
                    background-color: #1AD598;
                }

                span {
                    font-size: 16px;
                    font-weight: bold;
                    color: #8792B1;
                    z-index: 40;
                    padding-top: 4px;
                }

                @for $i from 1 through 6 {
                    &:nth-child(#{$i}) {
                        // left: calc(((#{$i} - 1) * 20%) - 30px);
                        left: calc(((#{$i} - 1) * 25%) - 30px);
                    }
                }
            }
        }
    }
}

.rebates,
#total-provided {
    .total-box {
        padding: 8px 12px;
    }
}

#vsdata h3,
#total-provided h3,
.rebates h3 {
    align-items: center;
    flex-wrap: wrap;

    .date-label {
        background-color: #DEE6F7;
        width: auto;
        text-align: center;
        border-radius: 6px;
        margin-left: 6px;
        padding: 2px 6px;
        font-size: 12px;
        font-weight: bold;
        color: #8792B1;
    }
}

@media screen and (min-width: $large-screen) {
    #left-menu,
    #main-header .logo .circle-button {
        display: none;
    }
}

@media screen and (max-width: $large-screen) {
    #main-header {
        nav.up {
            display: none;
        }
    }
}
@media screen and (max-width: 1050px) {
    #main-header {
        .search-box form {
            width: 90% !important;
            max-width: 300px;
        }
    }
}

@media screen and (max-width: 860px) {
    #main-header .logo {
        min-width: auto;
    }
}

.box-selection {
    width: 100%;
    height: auto;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-collapse: collapse;
}

.make-selection {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: 26    ;

    p {
        color: #999;
        font-size: 12px;
        margin: 0;
    }
}

.discount {
    width: 96px;
    height: 30px;
    border-radius: 6px;
    border: solid 1px #bdcbfc;
    background-color: #bdcbfc;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    label {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.83;
        
        text-align: center;
        color: #091f5a;
    }
}

.date-label {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    
    text-align: left;
    color: #2e2f32;
    margin-left: 12px;
    // margin-bottom: 20px;
}

#cover,
#print-info,
#account-information-cover {
    display: none;
}

.catch-up-message {
    width: 100%;
    padding: 16px 0;
    display: flex;
    justify-content: center;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    
    text-align: left;
    color: #56a36a;
}

/*
.brands-tracking-table-container {
    width: auto;
    overflow-x: scroll;
    margin-left: 200px;
    overflow-y: visible;
    padding: 24px 0;
}

.brands-tracking-table {
    width: 100%;
    border-collapse: separate;

    td,
    th {
        margin: 0;
        white-space: nowrap;
        padding: 16px 8px;
        border: solid 1px #f00;
        border-spacing: 0;
        border-collapse: separate;
    }
  
    th.customer-code {
        position: absolute;
        width: 200px;
        height: 55px;
        left: 31px;
        top: auto;
    }

    td.customer_code {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        
        text-align: left;
        color: #2e2f32;
        position: absolute;
        width: 200px;
        left: 31px;
        top: auto;
    }

    .number_of_brands {
        width: 200px;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        
        text-align: center;
        color: #2e2f32;
    }

    .brand {
        width: auto;
        min-width: 100px;


        &.null {
            background-color: #fff;
            content: '-';
        }

        &.green {
            background-color: #d1e7d7;
        }

        &.red {
            background-color: #fce5e5;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            
            text-align: center;
            color: #902727;
        }
    }

    th.number_of_brands {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        
        text-align: center;
        color: #7888a5;
    }

    th.brand {
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        
        text-align: center;
        color: #091f5a;
    }
}
*/


.brand-tracking-table {
    .dx-gridbase-container {
        padding: 0 !important;

        .brand-header {
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            
            text-align: left;
            color: #091f5a;
            text-transform: none !important;
        }

        .info-header {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            
            text-align: center;
            color: #7888a5;
            text-transform: none !important;
        }

        .empty-cell {
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            
            text-align: center;
            color: #999;
        }

        .red-cell {
            background-color: #fce5e5;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            
            text-align: center;
            color: #902727;
        }

        .green-cell {
            background-color: #d1e7d7;
        }
    }
}

.printable {
    .info-header,
    .brand-header {
        .dx-datagrid-text-content {
            // writing-mode: tb-rl;
            transform: rotateZ(270deg);
        }
    }

    td[role="columnheader"] {
        height: 140px;
    }

    .dx-datagrid-nowrap, .dx-datagrid-nowrap .dx-header-row>td>.dx-datagrid-text-content {
        white-space: normal !important;
    }
}
  
#pdf-loader {
    display: none;
}

#pdf-export-options {
    div.selection {
        width: 48%;
        display: inline-block;
        border-radius: 6px;
        border: solid 1px #dbdce0;

        &.selected {
            .dx-texteditor-container {
                border-radius: 4px !important;
                background-color: #bdcbfc !important;
            }
        }
    }

    .modal-body > *:nth-child(2n+2) {
        margin-left: 4%;
    }

    a, a:link, a:hover, a:visited {
        display: inline-block;
        padding: 7px 15px;
        width: 48%;
        border-radius: 6px;
        border: solid 1px #dbdce0;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        
        text-align: left;
        color: #031434;
        margin: 8px 0;
        cursor: pointer;

        /*
        &:nth-child(2n+2) {
            margin-left: 4%;
        }
        */

        &.selected {
            border-radius: 6px;
            background-color: #bdcbfc;
        }
    }

    .export-container {
        width: 100%;
        padding: 24px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .export-button {
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            
            text-align: center;
            color: #031434;
            border-radius: 6px;
            border: solid 1px #031434;
            background-color: transparent;
            padding: 4px 24px;
            cursor: pointer;

            &.disabled {
                opacity: 0.25;
                cursor: default;
            }
        }
    }
}

.printable {
    display: none;
    width: 100%;
    padding: 16px;
    background-color: #fff;
    border: 1px solid #DCE0EB;
    border-radius: 10px;
}

#pdf-export-options {
    .admin_rebates_edit form input, .admin_rebates_edit form select, .dx-dropdowneditor.dx-dropdowneditor-field-clickable, .dx-dropdowneditor.dx-dropdowneditor-field-clickable .dx-texteditor-input, .dx-datebox:not(.dx-datebox-native).dx-auto-width .dx-texteditor-input, .dx-datebox:not(.dx-datebox-native):not(.dx-texteditor-empty).dx-auto-width .dx-texteditor-input, .dx-placeholder {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        
        text-align: left;
        color: #031434;
    }
}