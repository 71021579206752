
$labelColor :#676768;
$borderColor :#efefef;
$titleColor: #2E2F32;
$selectedColor: #8DE0C6;
$chipColor: #87C3EA;

.checkboxselection{
    padding: 8px;
    border-color: $borderColor;
    border-width: 1px;
    border-style: solid;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    column-gap: 10px;
    border-radius: 4px;

    &--item{
        display: flex;
        column-gap: 5px;
    }

    &--title{
        font-weight: 800;
        text-align: left;
        color: $labelColor;
        font-size: 12px !important;
    }
}