$labelColor :#676768;
$borderColor :#DCE0EB;
$titleColor: #446B8F;
$selectedColor: #8DE0C6;
$chipColor: #87C3EA;

.approved {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    border-radius: 6px;
    background-color: #CFF9EB !important;
    color: #22D393 !important;
    padding: 4px;
}

.draft {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    border-radius: 6px;
    background-color: #FEF1DE !important;
    color: #FBCA82 !important;
    padding: 4px;
}
.table_header{
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}